/* stylelint-disable selector-class-pattern */

.input-group {
  .input-group-text {
    justify-content: center;
  }

  &.rounded-pill {
    > :first-child {
      @include border-start-radius(var(--bs-border-radius-pill));

      &.form-select-dropdown {
        .form-select-dropdown__control {
          padding-left: $input-padding-pill-x;

          &:not(.form-select-dropdown__control--menu-is-open) {
            @include border-start-radius(var(--bs-border-radius-pill));
          }

          &.form-select-dropdown__control--menu-is-open {
            @include border-top-start-radius($form-select-pill-open-border-radius);
          }
        }
      }

      &.input-group-text,
      &.form-control,
      &.form-select,
      &.btn {
        padding-left: $input-padding-pill-x;
      }
    }

    > :last-child {
      @include border-end-radius(var(--bs-border-radius-pill));

      &.form-select-dropdown {
        .form-select-dropdown__control {
          &:not(.form-select-dropdown__control--menu-is-open) {
            @include border-end-radius(var(--bs-border-radius-pill));
          }

          &.form-select-dropdown__control--menu-is-open {
            @include border-top-end-radius($form-select-pill-open-border-radius);
          }
        }
      }

      &.input-group-text,
      &.form-control,
      &.btn {
        padding-right: $input-padding-pill-x;
      }
    }

    &.input-group-sm {
      > :first-child,
      > :last-child {
        &.form-control {
          padding-left: $input-padding-pill-x-sm;
          padding-right: $input-padding-pill-x-sm;
        }
      }

      > :first-child {
        &.input-group-text,
        &.btn {
          padding-left: $input-padding-pill-x-sm + 0.5rem;
          padding-right: $input-padding-pill-x-sm;
        }

        &.form-select {
          padding-left: $input-padding-pill-x-sm;
        }

        &.form-select-dropdown {
          .form-select-dropdown__control {
            padding-left: $input-padding-pill-x-sm;
          }
        }
      }

      > :last-child {
        &.input-group-text,
        &.btn {
          padding-left: $input-padding-pill-x-sm;
          padding-right: $input-padding-pill-x-sm + 0.5rem;
        }
      }
    }

    &.input-group-lg {
      > :first-child,
      > :last-child {
        &.form-control {
          padding-left: $input-padding-pill-x-lg;
          padding-right: $input-padding-pill-x-lg;
        }
      }

      > :first-child {
        &.input-group-text,
        &.btn {
          padding-left: $input-padding-pill-x-lg + 0.5rem;
          padding-right: $input-padding-pill-x-lg;
        }

        &.form-select {
          padding-left: $input-padding-pill-x-lg;
        }

        &.form-select-dropdown {
          .form-select-dropdown__control {
            padding-left: $input-padding-pill-x-lg;
          }
        }
      }

      > :last-child {
        &.input-group-text,
        &.btn {
          padding-left: $input-padding-pill-x-lg;
          padding-right: $input-padding-pill-x-lg + 0.5rem;
        }
      }
    }
  }

  &.input-group-sm > .form-select {
    background-size: $form-select-bg-size-sm;
  }

  &.input-group-lg > .form-select {
    background-size: $form-select-bg-size-lg;
  }

  $validation-messages: "";

  @each $state in map-keys($form-validation-states) {
    $validation-messages: $validation-messages + ":not(." + unquote($state) + "-tooltip)" + ":not(." + unquote($state) + "-feedback)" + ":not(.is-" + unquote($state) + ")";
  }

  // Button overrides
  .btn {
    min-width: auto;
  }

  // Form check overrides
  .form-check,
  .form-check-input {
    margin: 0;
    padding: 0;
  }

  // Form select dropdown overrides (see version https://github.com/twbs/bootstrap/blob/main/scss/forms/_input-group.scss)
  > .form-select-dropdown {
    flex: 1 1 auto;
    min-width: 0;
    position: relative;
    width: 1%;
  }

  .form-select-dropdown__control--is-focused,
  .form-select-dropdown__control--menu-is-open {
    z-index: 5;
  }

  &.input-group-sm {
    &.rounded-pill {
      > :first-child {
        &.form-select-dropdown .form-select-dropdown__control.form-select-dropdown__control--menu-is-open {
          @include border-top-start-radius($form-select-pill-open-border-radius-sm);
        }
      }
    }

    .form-select-dropdown {
      .form-select-dropdown__control {
        padding: $form-select-padding-y-sm 0 $form-select-padding-y-sm $form-select-padding-x-sm;

        @include font-size($input-font-size-sm);
        @include border-radius($input-border-radius-sm);

        &.form-select-dropdown__control--menu-is-open {
          @include border-bottom-radius(0);
        }

        .form-select-dropdown__dropdown-indicator {
          background-size: $form-select-bg-size-sm;
        }
      }

      .form-select-dropdown__menu {
        @include font-size($input-font-size-sm);
      }
    }
  }

  &.input-group-lg {
    &.rounded-pill {
      > :first-child {
        &.form-select-dropdown .form-select-dropdown__control.form-select-dropdown__control--menu-is-open {
          @include border-top-start-radius($form-select-pill-open-border-radius-lg);
        }
      }
    }

    .form-select-dropdown {
      .form-select-dropdown__control {
        padding: $form-select-padding-y-lg 0 $form-select-padding-y-lg $form-select-padding-x-lg;

        @include font-size($input-font-size-lg);
        @include border-radius($input-border-radius-lg);

        &.form-select-dropdown__control--menu-is-open {
          @include border-bottom-radius(0);
        }

        .form-select-dropdown__dropdown-indicator {
          background-size: $form-select-bg-size-lg;
        }
      }

      .form-select-dropdown__menu {
        @include font-size($input-font-size-lg);
      }
    }
  }

  &:not(.has-validation) {
    > :not(:last-child, .dropdown-toggle, .dropdown-menu, .form-floating) {
      .form-select-dropdown__control {
        @include border-end-radius(0);
      }
    }
  }

  &.has-validation {
    > :nth-last-child(n + 3):not(.dropdown-toggle, .dropdown-menu, .form-floating) {
      .form-select-dropdown__control {
        @include border-end-radius(0);
      }
    }
  }

  > :not(:first-child):not(.dropdown-menu)#{$validation-messages} {
    .form-select-dropdown__control {
      margin-left: -$input-border-width;

      @include border-start-radius(0);
    }

    .form-select-dropdown__menu {
      margin-left: -$input-border-width;
      width: calc(100% + 1px);
    }
  }

  // Datepicker overrides
  .react-datepicker-wrapper {
    flex: 1 1 auto;
    min-width: 0;
    position: relative;
    width: 1%;
  }

  .react-datepicker-wrapper:not(:last-child),
  &.input-group-lg .react-datepicker-wrapper:not(:last-child) {
    .react-datepicker__input-container {
      > .form-control {
        @include border-end-radius(0);
      }
    }
  }

  .react-datepicker-wrapper:not(:first-child),
  &.input-group-lg .react-datepicker-wrapper:not(:first-child) {
    .react-datepicker__input-container {
      > .form-control {
        @include border-start-radius(0);
      }
    }
  }

  &.input-group-sm .react-datepicker-wrapper .react-datepicker__input-container {
    > .form-control {
      background-size: $form-select-bg-size-sm;
      padding: $input-padding-y-sm $input-padding-x-sm;

      @include font-size($input-font-size-sm);
    }
  }

  &.input-group-lg .react-datepicker-wrapper .react-datepicker__input-container {
    > .form-control {
      background-size: $form-select-bg-size-lg;
      padding: $input-padding-y-lg $input-padding-x-lg;

      @include font-size($input-font-size-lg);
      @include border-radius($input-border-radius-lg);
    }
  }

  &.rounded-pill > :first-child .react-datepicker__input-container .form-control {
    @include border-start-radius(var(--bs-border-radius-pill));
  }

  &.rounded-pill > :last-child .react-datepicker__input-container .form-control {
    @include border-end-radius(var(--bs-border-radius-pill));
  }
}
