.form-recaptcha {
  justify-self: flex-start;

  &.is-invalid,
  &.is-valid {
    border: 1px solid;
    border-radius: $border-radius-sm;
    padding-left: 2px;
    padding-top: 2px;
  }

  &.is-invalid {
    border-color: $danger;
  }

  &.is-valid {
    border-color: $success;
  }
}
