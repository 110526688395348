.form-phone-number {
  position: relative;

  &.is-valid {
    .react-international-phone-country-selector,
    input {
      border-color: var(--bs-form-valid-border-color) !important;
    }
  }

  &.is-invalid {
    .react-international-phone-country-selector,
    input {
      border-color: var(--bs-form-invalid-border-color) !important;
    }
  }

  &.is-disabled {
    background-color: $input-disabled-bg !important;
    border-color: $input-disabled-border-color !important;

    button {
      background-color: $input-disabled-bg !important;
    }
  }
}

.react-international-phone-input-container {
  position: relative;

  .react-international-phone-input.form-control,
  .react-international-phone-country-selector {
    border: var(--bs-border-width) solid $gray-400;
    border-radius: $border-radius;
    height: 40px;
    position: static;
  }

  .react-international-phone-input.form-control {
    border-bottom-left-radius: 0;
    border-left: none;
    border-top-left-radius: 0;
    font-size: $font-size-base;
    padding: 0.8rem 0.4rem;
  }

  .react-international-phone-country-selector {
    border-bottom-right-radius: 0;
    border-right: none;
    border-top-right-radius: 0;

    .react-international-phone-country-selector-button {
      border: none;
      height: 100%;
      padding-left: 0.4rem;
    }

    .react-international-phone-country-selector-dropdown {
      top: auto;
      width: 100%;
    }
  }
}
