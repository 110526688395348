.popover {
  left: 0;
  position: absolute;
  top: 0;
  width: max-content;

  .popover-arrow {
    position: absolute;
  }
}
