// Alert override helper
@mixin alert-variant-override(
  $background: null,
  $border: null,
  $color: null,
  $icon-color: null
) {
  @include set-css-var("alert-bg", $background);
  @include set-css-var("alert-border-color", $border);
  @include set-css-var("alert-color", $color);

  .alert-icon {
    color: $icon-color;
    font-size: $alert-icon-font-size;
    line-height: 2.2rem;
  }

  .alert-icon-lg {
    color: $icon-color;
    font-size: $alert-icon-font-size-lg;
    line-height: 2.2rem;
  }
}

.alert-transparent {
  @include set-css-var("alert-bg", transparent !important);
  @include set-css-var("alert-border-color", transparent !important);
}

.alert-sm {
  @include set-css-var("alert-padding-x", $alert-padding-x-sm);
  @include set-css-var("alert-padding-y", $alert-padding-y-sm);
}

.alert-primary {
  @include alert-variant-override($blue-100, $blue-200, $blue-500, $primary);
}

.alert-secondary {
  @include alert-variant-override($gray-100, $gray-300, $gray-800, $gray-800);
}

.alert-success {
  @include alert-variant-override($green-100, $green-200, $green-500, $success);
}

.alert-danger {
  @include alert-variant-override($red-100, $red-200, $red-500, $danger);
}

.alert-warning {
  @include alert-variant-override($yellow-100, $yellow-200, $yellow-500, $warning);
}

.alert-info {
  @include alert-variant-override($cyan-100, $cyan-200, $cyan-500, $info);
}

.alert-dark {
  @include alert-variant-override(null, null, null, $dark);
}

.alert-light {
  @include alert-variant-override(null, null, null, $light);
}

.alert-heading {
  font-weight: $font-weight-bold;
  margin-bottom: 0.5rem;
}

.alert-dismissible {
  .btn-close {
    padding: $alert-dismissible-btn-padding;
  }
}
