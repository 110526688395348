/* stylelint-disable selector-class-pattern */

@mixin form-validation-state-override(
  $state,
  $color,
  $icon,
  $tooltip-color: color-contrast($color),
  $tooltip-bg-color: rgba($color, $form-feedback-tooltip-opacity),
  $focus-box-shadow: 0 0 $input-btn-focus-blur $input-focus-width rgba($color, $input-btn-focus-color-opacity),
  $border-color: $color
) {
  .form-select-dropdown {
    @include form-validation-state-selector($state) {
      .form-select-dropdown__control {
        border-color: $border-color;

        &:focus {
          border-color: $border-color;
          box-shadow: $focus-box-shadow;
        }
      }

      .form-select-dropdown__menu {
        border-bottom-color: $border-color;
        border-left-color: $border-color;
        border-right-color: $border-color;
      }
    }
  }

  .input-group {
    > .form-select-dropdown:not(:focus) {
      @include form-validation-state-selector($state) {
        @if $state == "valid" {
          z-index: 3;
        } @else if $state == "invalid" {
          z-index: 4;
        }
      }
    }
  }
}

@each $state, $data in $form-validation-states {
  @include form-validation-state-override($state, $data...);
}
