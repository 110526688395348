.zone {
  --#{$prefix}zone-bg: transparent;
  --#{$prefix}zone-outline-color: $outline-color;
  --#{$prefix}zone-tooltip: unset;

  background-color: var(--#{$prefix}zone-bg);
  position: relative;

  &::after {
    background-color: $white;
    border: $border-width $border-style var(--#{$prefix}zone-outline-color);
    border-radius: $border-radius;
    content: var(--#{$prefix}zone-tooltip);
    font-size: $font-size-sm;
    left: 50%;
    padding-left: $zone-tooltip-padding-x;
    padding-right: $zone-tooltip-padding-x;
    position: absolute;
    top: 0;
    transform: translate(-50%, -100%);
    visibility: hidden;
    z-index: $zindex-tooltip;
  }

  &:hover {
    outline: $zone-outline-width $zone-outline-style var(--#{$prefix}zone-outline-color);

    &::after {
      visibility: visible;
    }
  }
}

// TODO: use bootstrap subtle colors after upgrading bootstrap here
@each $state in map-keys($theme-colors) {
  .zone-#{$state} {
    --#{$prefix}zone-bg: #{tint-color(map-get($theme-colors, $state), 80%)};
    --#{$prefix}zone-outline-color: #{tint-color(map-get($theme-colors, $state), 60%)};
  }
}

.zone-consultant-mode {
  --bs-zone-bg: #{$red-100};
  --bs-zone-outline-color: #{$red-200};
  --bs-zone-tooltip: "Not Visible to Customers";
}
