// Documentation: https://getbootstrap.com/docs/5.1/customize/sass
// Variable defaults: https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

// Gray color
$white: #fff;
$gray-100: #f1f3f4;
$gray-200: #d9dbdd;
$gray-300: #c8cacc;
$gray-400: #b7b9bb;
$gray-500: #969899;
$gray-600: #757677;
$gray-700: #535455;
$gray-800: #323333;
$gray-900: #101112;
$black: #000;
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);
$grays-named: (
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900
);

// Color
$blue: #579aca;
$indigo: #636bce;
$purple: #cfa1bd;
$pink: #f3e2e2;
$red: #d11717;
$orange: #f07400;
$yellow: #edb900;
$green: #6e9b3b;
$teal: #5a99a9;
$cyan: #96bbd1;

// All colors blue
$blue-100: #e8f6ff;
$blue-200: #9dcced;
$blue-300: $blue;
$blue-400: #41789f;
$blue-500: #2a4d66;
$blue-600: #122b3d;
$blue-700: #122b3d;
$blue-800: #061724;
$blue-900: #061724;
$blues: (
  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-700": $blue-700,
  "blue-800": $blue-800,
  "blue-900": $blue-900
);

// All colors cyan
$cyan-100: #e3f2fa;
$cyan-200: #bcd9eb;
$cyan-300: $cyan;
$cyan-400: #708b9e;
$cyan-500: #495963;
$cyan-600: #323d45;
$cyan-700: #323d45;
$cyan-800: #1c2226;
$cyan-900: #1c2226;
$cyans: (
  "cyan-100": $cyan-100,
  "cyan-200": $cyan-200,
  "cyan-300": $cyan-300,
  "cyan-400": $cyan-400,
  "cyan-500": $cyan-500,
  "cyan-600": $cyan-600,
  "cyan-700": $cyan-700,
  "cyan-800": $cyan-800,
  "cyan-900": $cyan-900
);

// All colors green
$green-100: #f3f9eb;
$green-200: #a1d26a;
$green-300: $green;
$green-400: #51732b;
$green-500: #354a1c;
$green-600: #1a250e;
$green-700: #1a250e;
$green-800: #0e1407;
$green-900: #0e1407;
$greens: (
  "green-100": $green-100,
  "green-200": $green-200,
  "green-300": $green-300,
  "green-400": $green-400,
  "green-500": $green-500,
  "green-600": $green-600,
  "green-700": $green-700,
  "green-800": $green-800,
  "green-900": $green-900
);

// All colors yellow
$yellow-100: #fdfbe9;
$yellow-200: #f5d731;
$yellow-300: $yellow;
$yellow-400: #a86800;
$yellow-500: #66410c;
$yellow-600: #422205;
$yellow-700: #422205;
$yellow-800: #291503;
$yellow-900: #291503;
$yellows: (
  "yellow-100": $yellow-100,
  "yellow-200": $yellow-200,
  "yellow-300": $yellow-300,
  "yellow-400": $yellow-400,
  "yellow-500": $yellow-500,
  "yellow-600": $yellow-600,
  "yellow-700": $yellow-700,
  "yellow-800": $yellow-800,
  "yellow-900": $yellow-900
);

// All colors orange
$orange-100: #fff8ec;
$orange-200: #ffc26d;
$orange-300: $orange;
$orange-400: #a14a0b;
$orange-500: #63240c;
$orange-600: #461604;
$orange-700: #461604;
$orange-800: #300f03;
$orange-900: #300f03;
$oranges: (
  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900
);

// All colors red
$red-100: #fff1f1;
$red-200: #ffa0a0;
$red-300: $red;
$red-400: #940707;
$red-500: #690000;
$red-600: #4a0000;
$red-700: #4a0000;
$red-800: #2e0808;
$red-900: #2e0808;
$reds: (
  "red-100": $red-100,
  "red-200": $red-200,
  "red-300": $red-300,
  "red-400": $red-400,
  "red-500": $red-500,
  "red-600": $red-600,
  "red-700": $red-700,
  "red-800": $red-800,
  "red-900": $red-900
);

// All colors purple
$purple-100: #fcf0f8;
$purple-200: #edd3e3;
$purple-300: $purple;
$purple-400: #ae7c9b;
$purple-500: #8d5a7a;
$purple-600: #6a3958;
$purple-700: #532843;
$purple-800: #38142b;
$purple-900: #170812;
$purples: (
  "purple-100": $purple-100,
  "purple-200": $purple-200,
  "purple-300": $purple-300,
  "purple-400": $purple-400,
  "purple-500": $purple-500,
  "purple-600": $purple-600,
  "purple-700": $purple-700,
  "purple-800": $purple-800,
  "purple-900": $purple-900
);

// All colors teal
$teal-100: #dff0f5;
$teal-200: #9fd5e3;
$teal-300: $teal;
$teal-400: #2b6c7d;
$teal-500: #003c4c;
$teal-600: #002f3b;
$teal-700: #00212a;
$teal-800: #011318;
$teal-900: #010a0d;
$teals: (
  "teal-100": $teal-100,
  "teal-200": $teal-200,
  "teal-300": $teal-300,
  "teal-400": $teal-400,
  "teal-500": $teal-500,
  "teal-600": $teal-600,
  "teal-700": $teal-700,
  "teal-800": $teal-800,
  "teal-900": $teal-900
);

// All colors indigo
$indigo-100: #e4e5f7;
$indigo-200: #b6bafc;
$indigo-300: $indigo;
$indigo-400: #3f47a3;
$indigo-500: #282f7e;
$indigo-600: #1c2269;
$indigo-700: #111654;
$indigo-800: #080c39;
$indigo-900: #030414;
$indigos: (
  "indigo-100": $indigo-100,
  "indigo-200": $indigo-200,
  "indigo-300": $indigo-300,
  "indigo-400": $indigo-400,
  "indigo-500": $indigo-500,
  "indigo-600": $indigo-600,
  "indigo-700": $indigo-700,
  "indigo-800": $indigo-800,
  "indigo-900": $indigo-900
);

// All colors pink
$pink-100: #faf6f6;
$pink-200: #f9f0f0;
$pink-300: $pink;
$pink-400: #dfc3c3;
$pink-500: #cfacac;
$pink-600: #b88e8e;
$pink-700: #825c5c;
$pink-800: #452d2d;
$pink-900: #261717;
$pinks: (
  "pink-100": $pink-100,
  "pink-200": $pink-200,
  "pink-300": $pink-300,
  "pink-400": $pink-400,
  "pink-500": $pink-500,
  "pink-600": $pink-600,
  "pink-700": $pink-700,
  "pink-800": $pink-800,
  "pink-900": $pink-900
);

// Gold colors
$gold-100: #fffaf0;
$gold-200: #f2d89d;
$gold-300: #ad8937;
$gold-400: #332b19;

// Badge colors
$lab-grown-blue-300: #295a90;

// New colors
$new-green-100: #eff;
$new-green-200: #89e5e5;
$new-green-300: #009494;
$new-green-400: #004d4d;

// StullerPay colors
$stullerpay-100: #e0ebff;
$stullerpay-200: #adc9ff;
$stullerpay-300: #4c7dff;
$stullerpay-400: #3c61c7;
$stullerpay-500: #1f3266;
$stullerpay-600: #0a1533;

// Custom colors (add new colors to `../docs/design/Colors.stories.mdx` and `../lib/types/bootstrap.ts`)
$custom-colors: (
  "lab-grown-blue-300": $lab-grown-blue-300,
  "new-green-100": $new-green-100,
  "new-green-200": $new-green-200,
  "new-green-300": $new-green-300,
  "new-green-400": $new-green-400,
  "stullerpay-100": $stullerpay-100,
  "stullerpay-200": $stullerpay-200,
  "stullerpay-300": $stullerpay-300,
  "stullerpay-400": $stullerpay-400,
  "stullerpay-500": $stullerpay-500,
  "stullerpay-600": $stullerpay-600,
  "gold-100": $gold-100,
  "gold-200": $gold-200,
  "gold-300": $gold-300,
  "gold-400": $gold-400
);

// Colors
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "black": $black,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800
);

// All colors (used in `.text-*`, `.bg-*`, `border-*`, and `outline-*` utilities)
$all-colors: map-merge-multiple(
  $colors,
  $grays-named,
  $blues,
  $indigos,
  $purples,
  $pinks,
  $reds,
  $oranges,
  $yellows,
  $greens,
  $teals,
  $cyans,
  $custom-colors
);
$all-colors-rgb: map-loop($all-colors, to-rgb, "$value");

// Theme color
$primary: $blue;
$secondary: $gray-700;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

// Showcase colors
$showcase-vars: (
  // Background colors
  "showcasebg_aqua": #9dc4b8,
  "showcasebg_blue": #99a9bb,
  "showcasebg_gem_aqua": #046d7a,
  "showcasebg_gem_blue": #1b047a,
  "showcasebg_gem_green": #047a23,
  "showcasebg_gem_pink": #7a0471,
  "showcasebg_gem_purple": #4d0477,
  "showcasebg_gem_red": #7a0417,
  "showcasebg_lightgrey": #c6beb6,
  "showcasebg_mustard": #e2bf71,
  "showcasebg_orange": #ff7e33,
  "showcasebg_purple": #c9a1bf,
  // Font color overrides
  "showcasebg_gem_aqua-color": $white,
  "showcasebg_gem_blue-color": $white,
  "showcasebg_gem_green-color": $white,
  "showcasebg_gem_pink-color": $white,
  "showcasebg_gem_purple-color": $white,
  "showcasebg_gem_red-color": $white,
  // Images
  "showcasebg_bindingLight": url("https://assets.stullercloud.com/web/static/i/showcase_backgrounds/binding_light.png"),
  "showcasebg_checkerboard": url("https://assets.stullercloud.com/web/static/i/showcase_backgrounds/ps_neutral.png"),
  "showcasebg_circles": url("https://assets.stullercloud.com/web/static/i/showcase_backgrounds/stacked_circles.png"),
  "showcasebg_crosshatch": url("https://assets.stullercloud.com/web/static/i/showcase_backgrounds/crosshatch.png"),
  "showcasebg_darksquare": url("https://assets.stullercloud.com/web/static/i/showcase_backgrounds/dark_square.png"),
  "showcasebg_diagonal": url("https://assets.stullercloud.com/web/static/i/showcase_backgrounds/diagonal.png"),
  "showcasebg_grid": url("https://assets.stullercloud.com/web/static/i/showcase_backgrounds/wavegrid.png"),
  "showcasebg_hexagon": url("https://assets.stullercloud.com/web/static/i/showcase_backgrounds/hexellence.png"),
  "showcasebg_nexus": url("https://assets.stullercloud.com/web/static/i/showcase_backgrounds/squairy_light.png"),
  "showcasebg_parquet": url("https://assets.stullercloud.com/web/static/i/showcase_backgrounds/parquet.png"),
  "showcasebg_squares": url("https://assets.stullercloud.com/web/static/i/showcase_backgrounds/square_bg_darker.png"),
  "showcasebg_ticks": url("https://assets.stullercloud.com/web/static/i/showcase_backgrounds/ticks.png")
);

// Options
$enable-caret: false;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-container-classes: true;
$enable-cssgrid: true;
$enable-button-pointers: true;
$enable-rfs: false;
$enable-validation-icons: false;
$enable-negative-margins: true;
$enable-deprecation-messages: true;
$enable-important-utilities: true;
$enable-dark-mode: true;
$color-mode-type: data;
$grid-row-columns: 12;

// Prefix for :root CSS
// Deprecated in v5.2.0 for the shorter `$prefix`
$variable-prefix: bs-;
$prefix: $variable-prefix;

// Spacer maps
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
  7: $spacer * 4,
  8: $spacer * 5
);

// Position map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
  n50: -50%,
  n100: -100%
);

// Body
$body-color: $gray-800;
$body-bg: $white;
$body-secondary-color: $gray-500;
$body-secondary-bg: $gray-200;
$body-tertiary-color: $gray-300;
$body-tertiary-bg: $gray-100;
$body-emphasis-color: $black;

// Paragraphs
$paragraph-margin-bottom: 1rem;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 2200px
);

// Container max widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 2100px
);

// Grid columns
$grid-gutter-width: 2rem;

// Border
$border-width: 1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);
$border-style: solid;
$border-color: $gray-300;
$border-color-translucent: rgba($black, 0.175);

// Outline
$outline-width: $border-width;
$outline-widths: $border-widths;
$outline-style: $border-style;
$outline-color: $border-color;
$outline-color-translucent: $border-color-translucent;

// Border radius
$border-radius: 0.3rem;
$border-radius-sm: 0.3rem;
$border-radius-lg: 0.5rem;
$border-radius-xl: 1rem;
$border-radius-xxl: 1.5rem;
$border-radius-xxxl: 3rem;
$border-radius-pill: 4.5rem;

// Box shadow
$box-shadow: 0 0.4rem 1.5rem rgb($black, 0.251);
$box-shadow-sm: 0 0.3rem 0.6rem rgb($black, 0.15);
$box-shadow-lg: 0 1rem 3rem rgb($black, 0.175);
$box-shadow-inset: inset 0 1px 2px rgb($black, 0.075);

// Component
$component-active-color: $white;
$component-active-bg: $primary;

// Blur
$blur-0: blur(0);
$blur-1: blur(2px);
$blur-2: blur(4px);
$blur-3: blur(8px);
$blur-4: blur(12px);
$blur-5: blur(16px);
$blur-6: blur(24px);
$blur: $blur-2;

// Scrollbar
$scrollbar-thumb-color: $primary;
$scrollbar-track-color: $gray-100;
$scrollbar-track-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
$scrollbar-width: auto;
$scrollbar-width-webkit: 0.7rem;
$scrollbar-border-radius: 1rem;

// Transition
$transition-duration: 0.2s;
$transition-function: ease-in-out;
$transition-base: all $transition-duration $transition-function;
$transition-fade: opacity $transition-duration linear;
$transition-collapse: height $transition-duration * 1.5 ease;
$transition-collapse-width: width $transition-duration * 1.5 ease;

// Aspect ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);

// Font
$font-family-sans-serif: "Roboto", sans-serif;
$font-family-monospace: "Courier New", monospace;
$font-size-root: 62.5%;
$font-size-xs: 1rem;
$font-size-sm: 1.2rem;
$font-size-base: 1.4rem;
$font-size-lg: 1.6rem;
$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 900;
$font-weight-base: $font-weight-normal;
$line-height-xs: 1.285;
$line-height-sm: 1.4286;
$line-height-base: 1.5715;
$line-height-lg: 1.7143;
$h1-font-size: 3.6rem;
$h1-font-size-sm: 3.2rem;
$h1-font-weight: $font-weight-light;
$h1-line-height: 4.4rem;
$h1-line-height-sm: 4rem;
$h2-font-size: 2.6rem;
$h2-font-size-sm: 2.4rem;
$h2-line-height: 3.2rem;
$h2-font-weight: $font-weight-light;
$h3-font-size: 1.8rem;
$h3-font-size-sm: 1.8rem;
$h3-font-weight: $font-weight-bold;
$h3-line-height: 2.6rem;
$h4-font-size: 1.6rem;
$h4-font-size-sm: 1.6rem;
$h4-font-weight: $font-weight-bold;
$h4-line-height: 2.4rem;
$h5-font-size: 1.4rem;
$h5-font-size-sm: 1.4rem;
$h5-font-weight: $font-weight-bold;
$h5-line-height: 2.2rem;
$h6-font-size: 1.2rem;
$h6-font-size-sm: 1.2rem;
$h6-font-weight: $font-weight-bold;
$h6-line-height: 1.8rem;
$letter-spacing-multiplier: 0.035em;
$word-spacing-multiplier: 0.035em;

// Font sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $font-size-xs
);

// Headings
$headings-margin-bottom: $spacer;
$headings-font-weight: inherit;

// Display Headings
$display-3-font-size: 7.2rem;
$display-3-font-size-sm: 6.2rem;
$display-4-font-size: 6.2rem;
$display-4-font-size-sm: 5.2rem;
$display-4-line-height: 7.2rem;
$display-4-line-height-sm: 5.8rem;
$display-5-font-size: 4.8rem;
$display-5-font-size-sm: 4.2rem;
$display-5-line-height: 5.4rem;
$display-5-line-height-sm: 4.8rem;
$display-6-font-size: $h1-font-size;
$display-6-font-size-sm: $h1-font-size-sm;
$display-6-line-height: $h1-line-height;
$display-6-line-height-sm: $h1-line-height-sm;
$display-font-sizes: (
  3: $display-3-font-size,
  4: $display-4-font-size,
  5: $display-5-font-size,
  6: $display-6-font-size
);
$display-font-weight: $font-weight-light;

// Links
$link-color: $blue-400;
$link-decoration: underline;
$link-hover-color: $primary;
$link-font-weight: $font-weight-bold;

// Type
$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;
$small-font-size: $font-size-sm;
$sub-sup-font-size: 0.75em;
$blockquote-margin-y: $spacer;
$blockquote-font-size: $font-size-base * 1.25;
$blockquote-footer-color: $gray-600;
$blockquote-footer-font-size: $small-font-size;
$hr-margin-y: $spacer;
$hr-opacity: 0.3;
$legend-margin-bottom: 0.5rem;
$legend-font-size: 1.5rem;
$legend-font-weight: null;

// Table
$table-border-radius: 5px;
$table-cell-padding-y: 1rem;
$table-cell-padding-x: 1rem;
$table-cell-padding-y-sm: 0.5rem;
$table-cell-padding-x-sm: 0.5rem;
$table-color: $body-color;

// Input btn
$input-btn-padding-y: 0.8rem;
$input-btn-padding-x: 1.3rem;
$input-btn-padding-y-sm: 0.7rem;
$input-btn-padding-x-sm: 0.8rem;
$input-btn-padding-y-lg: 0.894rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-focus-width: 0;

// Button
$btn-font-weight: $font-weight-medium;
$btn-box-shadow: 0;
$btn-disabled-opacity: 1;
$btn-disabled-bg: $gray-100;
$btn-disabled-border-color: $gray-400;
$btn-disabled-color: $gray-400;
$btn-outline-disabled-bg: $light;
$btn-outline-disabled-border-color: $gray-400;
$btn-outline-disabled-color: $gray-400;
$btn-min-width: 150px;
$btn-min-width-sm: 110px;
$btn-min-width-pill: 130px;
$btn-min-width-pill-sm: 75px;
$btn-link-disabled-color: $gray-400;
$btn-line-height: 1.5715;
$btn-line-height-sm: 1.167;
$btn-loading-bg: $gray-200;
$btn-loading-color: $gray-700;
$btn-loading-border-color: $gray-400;
$btn-loading-text-shadow: none;
$btn-loading-box-shadow: none;
$btn-loading-opacity: 0.6;

// Form label
$form-label-margin-bottom: 0.5rem;
$form-label-font-weight: $font-weight-bold;
$form-label-color: $body-color;

// Form input
$input-padding-y: $input-btn-padding-y;
$input-padding-x: 1rem;
$input-padding-pill-x: 1.5rem;
$input-font-weight: $font-weight-normal;
$input-padding-y-sm: 0.4578rem;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-padding-pill-x-sm: 1.2rem;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-padding-pill-x-lg: 1.5rem;
$input-disabled-color: $secondary;
$input-disabled-bg: $gray-100;
$input-disabled-border-color: $gray-300;
$input-border-color: $gray-400;
$input-box-shadow: 0;
$input-focus-border-color: $secondary;
$input-placeholder-color: $gray-500;
$input-border-radius: $border-radius;
$input-border-radius-sm: $border-radius-sm;
$input-border-radius-lg: $border-radius-lg;
$input-textarea-max-height-multiplier: 20;

// Form check
$form-check-input-width: 1.43em;
$form-check-padding-start: $form-check-input-width + 0.6em;
$form-check-margin-bottom: 0.8rem;
$form-check-label-color: $body-color;
$form-check-label-font-weight: $font-weight-base;
$form-check-input-border-color: $secondary;
$form-check-input-bg-color: $blue-400;
$form-check-input-border: 2.5px solid $form-check-input-border-color;
$form-check-input-border-radius: 0.4rem;
$form-check-input-focus-border: $form-check-input-bg-color;
$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-color: $form-check-input-focus-border;
$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $secondary;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-focus-border: $secondary;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='#{$form-check-input-indeterminate-color}' d='M0 256c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32z'/></svg>");
$form-check-radio-input-checked-color: $form-check-input-bg-color;
$form-check-radio-input-checked-bg-color: $form-check-input-checked-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{$form-check-input-checked-color}' d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-radio-input-checked-color}'/></svg>");
$form-check-radio-checked-bg-image-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$success}'/></svg>");
$form-check-radio-checked-bg-image-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$danger}'/></svg>");

// Form switch
$form-switch-color: $form-check-input-checked-color;
$form-switch-width: 2.5em;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -3.75 8 8'><circle r='3.5' fill='#{$form-switch-color}'/></svg>");
$form-switch-focus-color: $form-check-input-checked-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -3.75 8 8'><circle r='3.5' fill='#{$form-switch-focus-color}'/></svg>");
$form-switch-checked-color: $form-check-input-checked-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -3.75 8 8'><circle r='3.5' fill='#{$form-switch-checked-color}'/></svg>");

// Input group
$input-group-addon-font-weight: $font-weight-normal;
$input-group-addon-color: $body-color;
$input-group-addon-bg: $light;

// Form select
$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-indicator-padding: $input-padding-x * 3 + 1.2rem;
$form-select-indicator-padding-sm: $input-padding-x * 3 + 0.6rem;
$form-select-indicator-padding-lg: $input-padding-x * 3 + 1.6rem;
$form-select-disabled-color: $gray-400;
$form-select-disabled-bg: $gray-100;
$form-select-bg-position: right $form-select-padding-x center;
$form-select-bg-size: 22px;
$form-select-bg-size-sm: 16px;
$form-select-bg-size-lg: 25px;
$form-select-indicator-color: $primary;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{$form-select-indicator-color}' d='M432.6 209.3l-191.1 183.1C235.1 397.8 229.1 400 224 400s-11.97-2.219-16.59-6.688L15.41 209.3C5.814 200.2 5.502 184.1 14.69 175.4c9.125-9.625 24.38-9.938 33.91-.7187L224 342.8l175.4-168c9.5-9.219 24.78-8.906 33.91 .7187C442.5 184.1 442.2 200.2 432.6 209.3z'/></svg>");
$form-select-box-shadow: 0;
$form-select-padding-y-sm: 0.4579rem;

// Form select dropdown
$form-select-pill-open-border-radius: $border-radius-xl;
$form-select-pill-open-border-radius-sm: $border-radius-lg;
$form-select-pill-open-border-radius-lg: $border-radius-xxl;
$form-select-option-group-font-weight: $font-weight-bold;
$form-select-option-focused-bg: $light;
$form-select-option-selected-bg: $blue-100;
$form-select-option-focused-font-weight: $font-weight-normal;
$form-select-option-selected-font-weight: $form-select-option-focused-font-weight;
$form-select-option-selected-font-color: $blue-500;
$form-select-clear-indicator-color: $secondary;
$form-select-clear-indicator-bg-position: right 0 center;
$form-select-clear-indicator-bg-size: 22px;
$form-select-clear-indicator-width: 32px;
$form-select-clear-indicator-width-sm: 26px;
$form-select-clear-indicator-width-lg: 35px;
$form-select-clear-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='#{$form-select-clear-indicator-color}' d='M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z'/></svg>");
$form-select-menu-notice-font-weight: $font-weight-bold;

// Form date picker
$form-date-picker-padding: 15px;
$form-date-picker-header-bg: $gray-100;
$form-date-picker-header-time-padding: 10px;
$form-date-picker-select-padding: 2px;
$form-date-picker-month-select-width: 110px;
$form-date-picker-year-select-width: 78px;
$form-date-picker-font-size: $font-size-base;
$form-date-picker-navigation-offset: 26px;
$form-date-picker-navigation-width: 15px;
$form-date-picker-navigation-height: 30px;
$form-date-picker-navigation-icon-color: $gray-500;
$form-date-picker-navigation-icon-hover-color: $primary;
$form-date-picker-navigation-icon-width: 9px;
$form-date-picker-navigation-icon-offset: 3px;
$form-date-picker-navigation-icon-cut: -5px;
$form-date-picker-day-name-color: $gray-500;
$form-date-picker-day-width: 30px;
$form-date-picker-day-margin: 3px;
$form-date-picker-day-height: $form-date-picker-day-width;
$form-date-picker-day-selected-bg: $primary;
$form-date-picker-day-keyboard-selected-bg: $blue-100;
$form-date-picker-day-outside-month-color: $gray-400;
$form-date-picker-day-disabled-color: $gray-500;
$form-date-picker-day-disabled-bg: $gray-100;
$form-date-picker-year-width: 52px;
$form-date-picker-month-width: 46px;
$form-date-picker-selected-range-color: $blue-400;
$form-date-picker-time-container-width: 100px;
$form-date-picker-time-list-item-padding: 10px;

// Z-index levels map
$zindex-levels: (
  n3: -3,
  n2: -2,
  n1: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3
);

// Dropdown
$dropdown-min-width: 10rem;
$dropdown-font-size: $font-size-base;
$dropdown-box-shadow: $box-shadow-sm;
$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $link-color;
$dropdown-link-hover-bg: $gray-100;
$dropdown-link-disabled-color: $btn-link-disabled-color;
$dropdown-toggle-icon-spacing: 0.2em;

// Accordion
$accordion-padding-y: 1.5rem;
$accordion-padding-x: 1.5rem;
$accordion-color: $body-color;
$accordion-item-margin: 12px;
$accordion-body-padding-y: 1.5rem;
$accordion-body-font-weight: $font-weight-normal;
$accordion-border-color: $gray-400;
$accordion-button-color: $accordion-color;
$accordion-border-radius: 5px;
$accordion-button-bg: $gray-100;
$accordion-button-active-bg: $light;
$accordion-button-active-color: $body-color;
$accordion-button-focus-box-shadow: 0;
$accordion-button-min-height: 50px;
$accordion-button-min-height-sm: 40px;
$accordion-icon-width: 2rem;
$accordion-icon-color: $gray-800;
$accordion-icon-active-color: $accordion-icon-color;
$accordion-icon-transition: transform 0.2s ease-in-out, margin 0.2s ease-in-out;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{$accordion-icon-color}' d='M432.6 209.3l-191.1 183.1C235.1 397.8 229.1 400 224 400s-11.97-2.219-16.59-6.688L15.41 209.3C5.814 200.2 5.502 184.1 14.69 175.4c9.125-9.625 24.38-9.938 33.91-.7187L224 342.8l175.4-168c9.5-9.219 24.78-8.906 33.91 .7187C442.5 184.1 442.2 200.2 432.6 209.3z'/></svg>");
$accordion-button-icon-hover: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{$blue}' d='M432.6 209.3l-191.1 183.1C235.1 397.8 229.1 400 224 400s-11.97-2.219-16.59-6.688L15.41 209.3C5.814 200.2 5.502 184.1 14.69 175.4c9.125-9.625 24.38-9.938 33.91-.7187L224 342.8l175.4-168c9.5-9.219 24.78-8.906 33.91 .7187C442.5 184.1 442.2 200.2 432.6 209.3z'/></svg>");
$accordion-button-active-icon: $accordion-button-icon;
$accordion-icon-margin: 0 0 5px auto;
$accordion-icon-active-margin: 4px 0 0 auto;
$accordion-button-plus-minus-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{$accordion-icon-color}' d='M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z'/></svg>");
$accordion-button-plus-minus-icon-hover: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{$blue}' d='M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z'/></svg>");
$accordion-button-plus-minus-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{$accordion-icon-color}' d='M432 256c0 13.3-10.7 24-24 24L40 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l368 0c13.3 0 24 10.7 24 24z'/></svg>");
$accordion-button-plus-minus-active-icon-hover: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{$blue}' d='M432 256c0 13.3-10.7 24-24 24L40 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l368 0c13.3 0 24 10.7 24 24z'/></svg>");

// Card
$card-border-color: $gray-200;
$card-border-radius: $border-radius-lg;

// Popovers
$popover-font-size: $font-size-base;
$popover-max-width: 276px;
$popover-border-color: $border-color;
$popover-box-shadow: $box-shadow-sm;
$popover-body-padding-y: $spacer * 2;
$popover-body-padding-x: $spacer * 2;
$popover-arrow-width: 1.4rem;
$popover-arrow-height: 0.9rem;

// Badge
$badge-font-size: 0.8572em;
$badge-padding-y: 0.25em;
$badge-padding-x: 0.75em;

// Modal
$modal-inner-padding: $spacer * 1.5;
$modal-content-padding: 50px;
$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 60px;
$modal-dialog-margin-y-lg-up: 100px;
$modal-content-border-width: 0;
$modal-content-border-radius: $border-radius-xl;
$modal-backdrop-opacity: 0.3;
$modal-sm: 300px;
$modal-md: 600px;
$modal-lg: 900px;
$modal-xl: 1100px;

// Alert
$alert-border-radius: $border-radius-lg;
$alert-padding-y: 14px;
$alert-padding-x: 14px;
$alert-padding-y-sm: 5px;
$alert-padding-x-sm: 10px;
$alert-margin-bottom: 2rem;
$alert-icon-font-size: 1.6rem;
$alert-icon-font-size-lg: 3rem;
$alert-dismissible-padding-r: $alert-padding-x * 2 + 20px;
$alert-dismissible-btn-padding: $alert-padding-y * 1.1071 $alert-padding-x;

// Progress bars
$progress-bg: $gray-100;
$progress-bar-bg: $primary;
$progress-circle-height: 80px;
$progress-circle-bar-height: 3;
$progress-fill: none;
$progress-circle-animation-timing: 1s ease-out forwards;
$progress-circle-percent-font-size: 2.4rem;

// Image thumbnails
$thumbnail-box-shadow: none;

// Zone
$zone-outline-width: 2px;
$zone-outline-style: dashed;
$zone-tooltip-padding-x: 10px;

// Carousel
$carousel-transition-duration: 0.3s;
$carousel-transition: transform $carousel-transition-duration ease-in-out;
$carousel-indicators-bg-color: rgba($white, 0.8);
$carousel-dark-indicators-bg-color: $gray-200;
$carousel-indicators-padding-y: 8px;
$carousel-indicators-padding-x: 5px;
$carousel-indicators-margin-bottom: 15px;
$carousel-indicator-width: 15px;
$carousel-indicator-border-color: $gray-400;
$carousel-dark-indicator-border-color: $gray-400;
$carousel-indicator-height: 15px;
$carousel-indicator-hit-area-height: 0;
$carousel-indicator-spacer: 5px;
$carousel-indicator-opacity: 1;
$carousel-indicator-bg: $white;
$carousel-dark-indicator-bg: $white;
$carousel-indicator-active-bg: $gray-700;
$carousel-dark-indicator-active-bg: $gray-700;
$carousel-indicator-active-opacity: 1;
$carousel-indicator-transition: background-color 0.6s ease, border-color 0.6s ease;
$carousel-control-color: $gray-700;
$carousel-control-width: auto;
$carousel-control-spacer: 10px;
$carousel-control-opacity: 1;
$carousel-control-hover-opacity: 1;
$carousel-control-transition: background-color 0.15s ease;
$carousel-control-icon-width: 1.1rem;
$carousel-control-icon-bg-width: 3rem;
$carousel-control-icon-bg-color: rgba($white, 0.8);
$carousel-dark-control-icon-bg-color: $gray-200;
$carousel-control-icon-hover-bg-color: $white;
$carousel-dark-control-icon-hover-bg-color: $gray-200;
$carousel-control-prev-icon-bg-position: 9px;
$carousel-control-next-icon-bg-position: 10px;
$carousel-indicator-border-color: $gray-700;
$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' fill='#{$carousel-control-color}'><path d='M166.5 424.5l-143.1-152c-4.375-4.625-6.562-10.56-6.562-16.5c0-5.938 2.188-11.88 6.562-16.5l143.1-152c9.125-9.625 24.31-10.03 33.93-.9375c9.688 9.125 10.03 24.38 .9375 33.94l-128.4 135.5l128.4 135.5c9.094 9.562 8.75 24.75-.9375 33.94C190.9 434.5 175.7 434.1 166.5 424.5z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' fill='#{$carousel-control-color}'><path d='M89.45 87.5l143.1 152c4.375 4.625 6.562 10.56 6.562 16.5c0 5.937-2.188 11.87-6.562 16.5l-143.1 152C80.33 434.1 65.14 434.5 55.52 425.4c-9.688-9.125-10.03-24.38-.9375-33.94l128.4-135.5l-128.4-135.5C45.49 110.9 45.83 95.75 55.52 86.56C65.14 77.47 80.33 77.87 89.45 87.5z'/></svg>");
$carousel-dark-control-icon-filter: none;
$carousel-caption-spacer: 3.5rem;
$carousel-dark-caption-color: $black;

// Spinner
$spinner-width: 5rem;
$spinner-height: $spinner-width;
$spinner-vertical-align: -0.125em;
$spinner-border-width: 3px;
$spinner-border-vertical-align: -7px;
$spinner-width-xs: 1.6rem;
$spinner-height-xs: $spinner-width-xs;
$spinner-border-width-xs: 2px;
$spinner-border-vertical-align-xs: -2px;
$spinner-width-sm: 3.6rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: $spinner-border-width;
$spinner-border-vertical-align-sm: -8px;
$spinner-width-lg: 6.4rem;
$spinner-height-lg: $spinner-width-lg;
$spinner-border-width-lg: $spinner-border-width;
$spinner-border-vertical-align-lg: -8px;
$spinner-animation-speed: 1568ms;

// Close
$btn-close-width: 1.6rem;
$btn-close-height: $btn-close-width;
$btn-close-padding-x: 0.25em;
$btn-close-padding-y: $btn-close-padding-x;
$btn-close-color: $gray-600;
$btn-close-bg: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>');
$btn-close-focus-shadow: none;

// Offcanvas
$offcanvas-horizontal-width: 400px;
$offcanvas-vertical-height: auto;
$offcanvas-transition-duration: 0.3s;

// Code
$code-color: $pink-900;

// Breadcrumb
$breadcrumb-item-padding-x: 5px;
$breadcrumb-divider-color: var(--#{$prefix}body-color);
$breadcrumb-active-color: var(--#{$prefix}body-color);
$breadcrumb-margin-bottom: 1.5rem;
$breadcrumb-margin-top: 1.5rem;
$breadcrumb-divider: url("data:image/svg+xml,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-right' class='svg-inline--fa fa-chevron-right fa-xs' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='#{$breadcrumb-divider-color}' d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'></path></svg>");

// Navs
$nav-link-active-bg: $blue-400;
$nav-link-color: $gray-800;
$nav-link-hover-color: $white;
$nav-link-padding-y: 1.4rem;
$nav-link-padding-x: 2rem;
$nav-link-font-size: $font-size-base;
$nav-link-font-weight: $font-weight-bold;
$nav-tabs-border-radius: 10px;
$nav-tabs-link-active-bg: $blue-400;
$nav-tabs-link-active-border-color: $blue-400;

// Pagination
$pagination-padding-y: 0.4rem;
$pagination-padding-x: 0.2rem;
$pagination-font-size: $font-size-base;
$pagination-line-height: $line-height-base;
$pagination-border-radius: 0.3rem;
$pagination-margin-start: 0.5rem;
$pagination-active-bg: $blue-400;
$pagination-active-color: $white;
$pagination-active-border-color: $blue-400;
$pagination-bg: $gray-200;
$pagination-color: $gray-800;
$pagination-border-color: $gray-200;
$pagination-disabled-color: $gray-300;
$pagination-disabled-bg: $gray-200;
$pagination-disabled-border-color: $gray-200;
$pagination-hover-color: $white;
$pagination-hover-bg: $gray-500;
