.accordion-button {
  border-radius: $accordion-border-radius;
  font-size: inherit;
  font-weight: $font-weight-bold;
  line-height: inherit;
  min-height: $accordion-button-min-height;
  transition: border-bottom-left-radius 0.5s ease-in-out, border-bottom-right-radius 0.5s ease-in-out;

  &-sm {
    font-weight: $font-weight-normal;
    min-height: $accordion-button-min-height-sm;
  }

  &:not(.collapsed) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
    transition: initial;

    &::after {
      margin: $accordion-icon-active-margin;
    }
  }

  .plus-minus-button & {
    &:hover {
      &::after {
        background-image: escape-svg($accordion-button-plus-minus-active-icon-hover);
      }
    }

    &::after {
      background-image: escape-svg($accordion-button-plus-minus-active-icon);
      margin: 0 0 0 auto;
      transform: initial;
    }

    &.collapsed {
      &:hover {
        &::after {
          background-image: escape-svg($accordion-button-plus-minus-icon-hover);
        }
      }

      &::after {
        background-image: escape-svg($accordion-button-plus-minus-icon);
      }
    }
  }

  &:hover {
    &::after {
      background-image: escape-svg($accordion-button-icon-hover);
    }
  }

  &::after {
    margin: $accordion-icon-margin;
  }
}

.accordion-item {
  border-radius: var(--#{$prefix}accordion-border-radius);
  margin-bottom: $accordion-item-margin;

  &:not(:first-of-type) {
    border: var(--#{$prefix}accordion-border-width) solid var(--#{$prefix}accordion-border-color);
  }

  &:hover {
    border-color: $gray-500;
  }
}

.accordion-body {
  font-weight: $accordion-body-font-weight;
}

.accordion-flush {
  .accordion-item {
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    margin-bottom: 0;
  }
}
