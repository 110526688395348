/* stylelint-disable selector-class-pattern */
/* stylelint-disable scss/at-extend-no-missing-placeholder */

@import "react-datepicker/dist/react-datepicker.css";

.form-date-picker {
  display: block;
}

.form-date-picker-popover {
  max-width: 100%;

  .react-datepicker__triangle {
    stroke: $popover-border-color;
  }

  .react-datepicker {
    border: none;
    border-radius: $popover-border-radius;
    font-family: $font-family-sans-serif;
  }

  .react-datepicker__navigation {
    height: $form-date-picker-navigation-height;
    margin: 0;
    padding: 0;
    top: $form-date-picker-padding;
    width: $form-date-picker-navigation-width;

    &:hover {
      .react-datepicker__navigation-icon {
        &::before {
          background-color: $form-date-picker-navigation-icon-hover-color;
        }
      }
    }

    &.react-datepicker__navigation--previous {
      left: $form-date-picker-navigation-offset;
    }

    &.react-datepicker__navigation--next {
      right: $form-date-picker-navigation-offset;

      &.react-datepicker__navigation--next--with-time {
        right: $form-date-picker-navigation-offset + $form-date-picker-time-container-width;
      }
    }
  }

  .react-datepicker__navigation-icon {
    height: $form-date-picker-navigation-height;
    overflow: hidden;
    width: $form-date-picker-navigation-icon-width;

    &::before {
      background-color: $form-date-picker-navigation-icon-color;
      border: none;
      height: $form-date-picker-navigation-icon-width;
      top: $form-date-picker-navigation-icon-width + 2px;
      width: $form-date-picker-navigation-icon-width;
    }

    &.react-datepicker__navigation-icon--previous {
      right: $form-date-picker-navigation-icon-offset;

      &::before {
        right: $form-date-picker-navigation-icon-cut;
      }
    }

    &.react-datepicker__navigation-icon--next {
      left: $form-date-picker-navigation-icon-offset;

      &::before {
        left: $form-date-picker-navigation-icon-cut;
      }
    }
  }

  .react-datepicker__month-container,
  .react-datepicker__year--container {
    border-radius: $popover-border-radius;
  }

  .react-datepicker__year--container {
    padding-bottom: 3px;
  }

  .react-datepicker__header {
    background-color: $form-date-picker-header-bg;
    border: 0;
    border-top-left-radius: $popover-border-radius;
    padding: $form-date-picker-padding $form-date-picker-padding 0 $form-date-picker-padding;
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: $popover-border-radius;
  }

  .react-datepicker__header--time:not(.react-datepicker__header--time--only) {
    border-top-left-radius: 0;
  }

  .react-datepicker__header--time {
    padding: $form-date-picker-header-time-padding;
  }

  .react-datepicker-time__header {
    font-size: $form-date-picker-font-size;
  }

  .react-datepicker-year-header {
    font-size: $form-date-picker-font-size;
    padding-bottom: 11px;
    padding-top: 12px;
  }

  .react-datepicker__year {
    font-size: $form-date-picker-font-size;
  }

  .react-datepicker__current-month--hasMonthDropdown {
    display: none;
  }

  .react-datepicker__header__dropdown {
    padding: 0 $form-date-picker-navigation-width;
  }

  .react-datepicker__month-dropdown-container {
    margin: 0 $form-date-picker-select-padding;

    .react-datepicker__month-select {
      width: $form-date-picker-month-select-width;
    }
  }

  .react-datepicker__year-dropdown-container {
    margin: 0 $form-date-picker-select-padding;

    .react-datepicker__year-select {
      width: $form-date-picker-year-select-width;
    }
  }

  select {
    @extend .form-select;
    @extend .form-select-sm;
  }

  .react-datepicker__day-name {
    border: $border-width $border-style transparent;
    color: $form-date-picker-day-name-color;
    font-size: $form-date-picker-font-size;
    font-weight: $font-weight-bold;
    height: $form-date-picker-day-width;
    line-height: $form-date-picker-day-width;
    margin: $form-date-picker-day-margin;
    width: $form-date-picker-day-width;
  }

  .react-datepicker__time-container {
    width: $form-date-picker-time-container-width;

    .react-datepicker__time {
      .react-datepicker__time-box {
        width: 100%;

        .react-datepicker__time-list {
          @include scrollbar;

          .react-datepicker__time-list-item {
            font-size: $form-date-picker-font-size;
            height: unset;
            padding: $form-date-picker-time-list-item-padding;
            text-align: right;

            &.react-datepicker__time-list-item--selected {
              background-color: $form-select-option-selected-bg;
              color: $form-select-option-selected-font-color;

              &:hover {
                background-color: $form-select-option-selected-bg;
              }
            }

            &:hover {
              background-color: $form-select-option-focused-bg;
              font-weight: $form-select-option-focused-font-weight;
            }
          }
        }
      }
    }
  }

  .react-datepicker__month {
    margin: 0 0 -2px;
    padding: 10px 0 2px;
  }

  .react-datepicker__day,
  .react-datepicker__year-text,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text {
    border: $border-width $border-style transparent;
    border-radius: $border-radius;
    font-size: $form-date-picker-font-size;
    height: $form-date-picker-day-width;
    line-height: $form-date-picker-day-width;
    margin: $form-date-picker-day-margin;
    width: $form-date-picker-day-width;

    &:hover:not(
    .react-datepicker__day--disabled,
    .react-datepicker__year-text--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled) {
      background-color: transparent !important;
      border: $border-width $border-style $border-color !important;
      color: $body-color !important;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__year-text--selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__quarter-text--selected {
    background-color: $form-date-picker-day-selected-bg;
    color: $white;
  }

  .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range),
  .react-datepicker__year-text--keyboard-selected:not(.react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range),
  .react-datepicker__month-text--keyboard-selected:not(.react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range),
  .react-datepicker__quarter-text--keyboard-selected:not(.react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range) {
    background-color: $form-date-picker-day-keyboard-selected-bg;
  }

  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__year-text--selecting-range-start,
  .react-datepicker__year-text--selecting-range-end,
  .react-datepicker__year-text--in-range,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__month-text--selecting-range-start,
  .react-datepicker__month-text--selecting-range-end,
  .react-datepicker__month-text--in-range,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__quarter-text--selecting-range-start,
  .react-datepicker__quarter-text--selecting-range-end,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__quarter-text--in-selecting-range {
    background-color: $form-date-picker-selected-range-color;
    color: $white;

    &.react-datepicker__day--outside-month {
      background-color: $form-date-picker-day-selected-bg;
      color: $white;
    }
  }

  .react-datepicker__day--disabled,
  .react-datepicker__year-text--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled {
    background-color: $form-date-picker-day-disabled-bg;
    color: $form-date-picker-day-disabled-color;
  }

  .react-datepicker__day--outside-month {
    color: $form-date-picker-day-outside-month-color;
  }

  .react-datepicker__year-wrapper {
    justify-content: center;
    max-width: 249px;
  }

  .react-datepicker__year-text {
    width: $form-date-picker-year-width;
  }

  .react-datepicker__month-wrapper {
    min-width: 180px;
  }

  .react-datepicker__month-text {
    width: $form-date-picker-month-width;
  }

  .react-datepicker__quarter-wrapper {
    min-width: 158px;
  }

  .react-datepicker__monthPicker,
  .react-datepicker__quarterPicker {
    padding-top: 7px;
  }
}

.form-date-picker-popover-year,
.form-date-picker-popover-month-year,
.form-date-picker-popover-quarter-year {
  .react-datepicker__navigation {
    top: 6px;
  }
}

.form-date-picker-start,
.form-date-picker-end {
  display: inline-block;
  width: 50%;

  ~ .react-datepicker__tab-loop {
    display: inline-block;
  }
}

.form-date-picker-start {
  .input-group-text {
    border-bottom-right-radius: 0;
    border-right: 0;
    border-top-right-radius: 0;
  }
}

.form-date-picker-end {
  .form-control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .input-group-text {
    border-bottom-right-radius: 0;
    border-right: 0;
    border-top-right-radius: 0;
  }
}

.react-datepicker__tab-loop {
  display: block;
  height: 0;
  width: 0;
}
