.pagination {
  margin-bottom: 0;

  .page-item {
    cursor: pointer;
    padding-bottom: 0;

    &.disabled {
      cursor: initial;
    }

    &.active {
      .page-link {
        font-weight: $font-weight-bold;
      }
    }

    &.ellipsis {
      .page-link {
        background-color: transparent;
        border-color: transparent;
        color: $gray-200;
        cursor: initial;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  }

  .page-link {
    min-width: 3rem;
  }

  a.page-link {
    font-weight: $font-weight-normal;
    text-align: center;
  }
}
