.dropdown-menu {
  display: block;
  position: static;

  li {
    padding-bottom: 0;
  }
}

.dropdown-toggle-icon {
  vertical-align: text-top !important;
}
