// Font size override helper
@mixin font-size-override(
  $font-size-smaller: null,
  $line-height-smaller: null
) {
  &:not(.fs-force) {
    @include media-breakpoint-down(md) {
      font-size: $font-size-smaller !important;
      line-height: $line-height-smaller !important;
    }
  }
}

.fs-1,
%fs-1 {
  @include font-size-override($h1-font-size-sm, $h1-line-height-sm);
}

.fs-2,
%fs-2 {
  @include font-size-override($h2-font-size-sm, null);
}

.fs-3,
%fs-3 {
  @include font-size-override($h3-font-size-sm, null);
}

.fs-4,
%fs-4 {
  @include font-size-override($h4-font-size-sm, null);
}

.fs-5,
%fs-5 {
  @include font-size-override($h5-font-size-sm, null);
}

.fs-6,
%fs-6 {
  @include font-size-override($h6-font-size-sm, null);
}

// Headers and header classes
h1,
.h1 {
  @extend %fs-1;

  font-weight: $h1-font-weight;
  letter-spacing: -0.015em;
  line-height: $h1-line-height;
  word-spacing: 0.06em;
}

h2,
.h2 {
  @extend %fs-2;

  font-weight: $h2-font-weight;
  letter-spacing: -0.01em;
  line-height: $h2-line-height;
  word-spacing: 0.06em;
}

h3,
.h3 {
  @extend %fs-3;

  font-weight: $h3-font-weight;
  line-height: $h3-line-height;
}

h4,
.h4 {
  @extend %fs-4;

  font-weight: $h4-font-weight;
  line-height: $h4-line-height;
}

h5,
.h5 {
  @extend %fs-5;

  font-weight: $h5-font-weight;
  line-height: $h5-line-height;
}

h6,
.h6 {
  @extend %fs-6;

  font-weight: $h6-font-weight;
  line-height: $h6-line-height;
}

.display {
  &-3 {
    @include font-size-override($display-3-font-size-sm, $display-4-line-height-sm);

    letter-spacing: normal;
    line-height: $display-4-line-height;
    word-spacing: normal;
  }

  &-4 {
    @include font-size-override($display-4-font-size-sm, $display-4-line-height-sm);

    letter-spacing: normal;
    line-height: $display-4-line-height;
    word-spacing: normal;
  }

  &-5 {
    @include font-size-override($display-5-font-size-sm, $display-5-line-height-sm);

    letter-spacing: normal;
    line-height: $display-5-line-height;
    word-spacing: normal;
  }

  &-6 {
    @include font-size-override($display-6-font-size-sm, $display-6-line-height-sm);

    letter-spacing: -0.015em;
    line-height: $display-6-line-height;
    word-spacing: normal;
  }
}
