.shadow-container {
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    pointer-events: none;
  }

  &.shadow-container-horizontal {
    &::before,
    &::after {
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      width: 25px;
      z-index: 1;
    }
  }

  &.shadow-container-vertical {
    &::before,
    &::after {
      content: "";
      height: 25px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }

  --start-shadow-offset: 0;
  --end-shadow-offset: 0;

  &:not(.shadow-container-start) {
    &.shadow-container-horizontal {
      &::before {
        background: radial-gradient(farthest-side at 0 50%, color(srgb 0 0 0 / 15%), color(srgb 0 0 0 / 0%)) center top;
        left: var(--start-shadow-offset);
      }
    }

    &.shadow-container-vertical {
      &::before {
        background: radial-gradient(farthest-side at 50% 0, color(srgb 0 0 0 / 15%), color(srgb 0 0 0 / 0%)) center top;
        top: var(--start-shadow-offset);
      }
    }
  }

  &:not(.shadow-container-end) {
    &.shadow-container-horizontal {
      &::after {
        background: radial-gradient(farthest-side at 100% 50%, color(srgb 0 0 0 / 15%), color(srgb 0 0 0 / 0%)) center bottom;
        right: var(--end-shadow-offset);
      }
    }

    &.shadow-container-vertical {
      &::after {
        background: radial-gradient(farthest-side at 50% 100%, color(srgb 0 0 0 / 15%), color(srgb 0 0 0 / 0%)) center bottom;
        bottom: var(--end-shadow-offset);
      }
    }
  }
}
