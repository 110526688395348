.breadcrumb {
  margin-top: $breadcrumb-margin-top;

  &-item {
    font-size: $font-size-sm;
    padding-bottom: 0;

    &-link {
      font-weight: normal;
    }

    &::before {
      margin-top: 1px;
      width: 1.2rem;
    }
  }
}
