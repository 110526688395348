// Sets the Bootstrap CSS var in value is not null
@mixin set-css-var($name, $value) {
  @if $value {
    --#{$prefix}#{$name}: #{$value};
  }
}

// Adds custom scrollbar to element/class
@mixin scrollbar {
  // Only do this in Firefox so that we can still use the old method in Chrome/Safari
  @supports (-moz-appearance:none) {
    scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color;
    scrollbar-width: $scrollbar-width;
  }

  &::-webkit-scrollbar {
    height: $scrollbar-width-webkit;
    width: $scrollbar-width-webkit;
  }

  &::-webkit-scrollbar-track {
    border-radius: $scrollbar-border-radius;
    box-shadow: $scrollbar-track-box-shadow;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb-color;
    border-radius: $scrollbar-border-radius;
  }
}
