.form-check-input {
  cursor: pointer;
  float: none !important;
  margin-top: 0;

  &[type="checkbox"] {
    background-size: auto;
  }

  &:checked {
    &[type="radio"] {
      background-color: $form-check-radio-input-checked-bg-color;

      &.is-valid {
        background-image: escape-svg($form-check-radio-checked-bg-image-valid);
      }

      &.is-invalid {
        background-image: escape-svg($form-check-radio-checked-bg-image-invalid);
      }
    }
  }

  &:hover:not(:disabled, .is-invalid, .is-valid) {
    border-color: $form-check-input-focus-border;
  }
}

.form-check-reverse {
  .form-check-input {
    float: right !important;
  }

  .form-check-label {
    padding-left: 0;
  }
}

.form-check-label {
  cursor: pointer;
  font-weight: $form-check-label-font-weight;
  padding-left: 0.75rem;
}

.form-switch {
  .form-check-input {
    background-color: $form-check-input-border-color;

    &:checked {
      background-color: $form-check-input-checked-bg-color;

      &.is-valid {
        background-color: $success;
      }

      &.is-invalid {
        background-color: $danger;
      }
    }
  }
}

.btn-check {
  &:checked + .btn {
    background-color: $blue-400 !important;
    border-color: $blue-400;
    color: $white;
  }

  &:disabled + .btn {
    background-color: $gray-100;
    cursor: not-allowed;
    opacity: 0.5;
  }

  + .btn {
    background-color: $white;
    border-color: $gray-400;
    border-radius: $border-radius-pill;
    min-width: auto;
    position: relative;

    &:hover {
      background-color: $gray-500;
      border-color: $gray-500;
      color: $white;
    }

    + .btn-check + .btn {
      margin-left: 5px;

      &:checked {
        background-color: $blue-400;
        border-color: $blue-400;
        color: $white;
      }
    }
  }
}
