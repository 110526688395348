/* stylelint-disable property-no-vendor-prefix */

.form-number-hide-arrows {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}
