.table-rounded {
  border-collapse: separate;
  border-left-width: 0;
  border-spacing: 0;

  & *:nth-child(1) > tr:first-child > *:first-child {
    border-top-left-radius: $table-border-radius;
  }

  & *:nth-child(1) > tr:first-child > *:last-child {
    border-top-right-radius: $table-border-radius;
  }

  & *:last-child > tr:last-child > *:first-child {
    border-bottom-left-radius: $table-border-radius;
  }

  & *:last-child > tr:last-child > *:last-child {
    border-bottom-right-radius: $table-border-radius;
  }

  &.table-bordered {
    td,
    th {
      border-left-width: 1px;
      border-right-width: 0;
      border-top-width: 1px;

      &:last-of-type:not(:first-of-type) {
        border-right-width: 1px;
      }
    }

    *:last-child > tr:last-child > * {
      border-bottom-width: 1px;
    }
  }
}
