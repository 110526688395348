.form-control {
  &:disabled,
  &[readonly] {
    color: $input-disabled-color;
  }

  &.rounded-pill {
    padding-left: $input-padding-pill-x;
    padding-right: $input-padding-pill-x;
  }
}

.form-control-sm {
  &.rounded-pill {
    padding-left: $input-padding-pill-x-sm;
    padding-right: $input-padding-pill-x-sm;
  }
}

.form-control-lg {
  &.rounded-pill {
    padding-left: $input-padding-pill-x-lg;
    padding-right: $input-padding-pill-x-lg;
  }
}

textarea {
  @include scrollbar;

  &.form-control {
    max-height: calc($input-height * $input-textarea-max-height-multiplier);
  }

  &.form-control-sm {
    max-height: calc($input-height-sm * $input-textarea-max-height-multiplier);
  }

  &.form-control-lg {
    max-height: calc($input-height-lg * $input-textarea-max-height-multiplier);
  }
}
