.nav-tabs {
  border-bottom: 0;

  .nav-item {
    @include media-breakpoint-up(lg) {
      padding-bottom: 1px;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  .nav-link {
    @include media-breakpoint-down(lg) {
      --bs-nav-tabs-border-radius: 50px;

      align-items: center;
      border-bottom-left-radius: var(--bs-nav-tabs-border-radius);
      border-bottom-right-radius: var(--bs-nav-tabs-border-radius);
      display: flex;
      flex-direction: row;
      min-height: 40px;
      padding-bottom: 0;
      padding-top: 0;
      text-align: left;
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      border-bottom: none;
    }

    border-color: var(--#{$prefix}border-color);
    border-width: $nav-tabs-border-width;
    color: $black;

    svg {
      color: $blue-300;
      transition: 0.15s color;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    &:hover {
      background-color: $nav-tabs-link-active-bg;
      border-color: $nav-tabs-link-active-border-color;

      svg {
        color: $white;
      }
    }

    color: $white;

    svg {
      color: $white;
    }
  }

  .nav-link.disabled,
  .nav-item.show .nav-link {
    background-color: $gray-100;
    color: $nav-link-disabled-color;
  }

  .nav-link.disabled {
    span.badge {
      background-color: $nav-link-disabled-color !important;
      border-color: $nav-link-disabled-color !important;
    }
  }
}

.nav {
  .nav-link {
    &:hover {
      background-color: $gray-500;
      border-color: $gray-500;
      color: $white;

      svg {
        color: $white;
      }
    }
  }

  .nav-link.disabled,
  .nav-item.show .nav-link {
    background-color: $gray-100;
    color: $nav-link-disabled-color;
  }

  .nav-link.disabled {
    span.badge {
      background-color: $nav-link-disabled-color !important;
      border-color: $nav-link-disabled-color !important;
    }
  }
}
