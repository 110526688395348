.form-select {
  &.rounded-pill {
    padding-left: $input-padding-pill-x;
    padding-right: $input-padding-pill-x;
  }
}

.form-select-sm {
  background-size: $form-select-bg-size-sm;
  padding-right: $form-select-indicator-padding-sm;

  &.rounded-pill {
    padding-left: $input-padding-pill-x-sm;
    padding-right: $input-padding-pill-x-sm;
  }
}

.form-select-lg {
  background-size: $form-select-bg-size-lg;
  padding-right: $form-select-indicator-padding-lg;

  &.rounded-pill {
    padding-left: $input-padding-pill-x-lg;
    padding-right: $input-padding-pill-x-lg;
  }
}
