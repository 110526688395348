.form-credit-card-number {
  position: relative;

  input {
    padding-left: 50px !important;
  }

  &-container {
    position: relative;
  }

  .form-credit-card-icon {
    left: 10px;
    position: absolute;
    top: 9px;
  }
}
