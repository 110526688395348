@mixin colored-link-variant-override($color, $color-hover) {
  color: $color !important;
  text-decoration-color: $color !important;

  &:hover,
  &:focus {
    color: $color-hover !important;
    text-decoration-color: $color-hover !important;
  }
}

.link-white,
%link-white {
  @include colored-link-variant-override($white, $cyan-200);
}

.link-dark {
  @include colored-link-variant-override($body-color, $link-color);
}

.link-gray-200 {
  @include colored-link-variant-override($gray-200, $white);
}

.link-disabled-gray-400 {
  @include colored-link-variant-override($gray-400, $gray-400);
}

.link-gray-600 {
  @include colored-link-variant-override($gray-600, $link-color);
}

.links-white {
  a:not(.btn) {
    @extend %link-white;
  }
}
