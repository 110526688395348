.bg-glass {
  &-light {
    background: linear-gradient(116deg, rgb(255 255 255 / 81%) 0%, rgb(255 255 255 / 36%) 100%);
  }

  &-dark {
    background: linear-gradient(116deg, rgb(0 0 0 / 68%) 0%, rgb(0 0 0 / 34%) 100%);
  }

  &-gray {
    background: linear-gradient(116deg, rgb(238 238 238 / 68%) 0%, rgb(238 238 238 / 34%) 100%);
  }

  &-blur {
    backdrop-filter: $blur-5;
  }
}

.bg-video {
  height: 100%;
  inset: 0;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  user-select: none;
  width: 100%;
  z-index: 0;
}
