.progress {
  --#{$prefix}progress-circle-height: #{$progress-circle-height};
  --#{$prefix}progress-circle-bar-height: #{$progress-circle-bar-height};
  --#{$prefix}progress-fill: #{$progress-fill};
}

.progress-circle {
  background-color: transparent;
  box-shadow: none;
  display: inline-block;
  height: auto;
  position: relative;

  .progress-circle-bar {
    --#{$prefix}progress-bg: #{$progress-bg};
    --#{$prefix}progress-bar-bg: #{$progress-bar-bg};

    display: block;
    height: var(--#{$prefix}progress-circle-height);
    margin: 0 auto;

    .progress-circle-bg {
      fill: var(--#{$prefix}progress-fill);
      stroke: var(--#{$prefix}progress-bg);
      stroke-width: var(--#{$prefix}progress-circle-bar-height);
    }

    .progress-circle-fg {
      animation: progress-circle $progress-circle-animation-timing;
      fill: none;
      stroke: var(--#{$prefix}progress-bar-bg);
      stroke-linecap: butt;
      stroke-width: var(--#{$prefix}progress-circle-bar-height);
    }
  }

  .progress-circle-percent {
    font-size: $progress-circle-percent-font-size;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes progress-circle {
  0% {
    stroke-dasharray: 0 100;
  }
}
