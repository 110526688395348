.form-option-card {
  padding: 15px;

  label {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
  }

  input {
    margin-bottom: auto;
    margin-right: 10px;
    margin-top: auto;
  }

  .card-body {
    padding: 0;
  }

  .form-option-card-title {
    margin-bottom: 0;
  }

  .form-option-card-description {
    font-weight: 300;
  }

  &.is-checked {
    background-color: $blue-100;
    border-color: $blue-200;
    color: $blue-500;

    .form-option-card-title,
    .form-option-card-description {
      color: $blue-500;
    }
  }

  &.is-invalid {
    border-color: var(--bs-form-invalid-border-color);
  }

  &.is-valid {
    border-color: var(--bs-form-valid-border-color);
  }

  &.is-disabled {
    background-color: $gray-100;
    opacity: 0.5;
  }
}
