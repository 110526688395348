/* stylelint-disable selector-class-pattern */

@import "react-toastify/dist/ReactToastify.min.css";

:root {
  --toastify-toast-width: 350px;
  --toastify-color-progress-bgo: 0.35;
}

.Toastify {
  position: relative;
}

.Toastify__toast-container--top-right {
  overflow: hidden;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 0;
}

.Toastify__toast-body {
  .alert {
    border: 0;
    margin: 0;
    padding: 0;
  }
}

.Toastify__progress-bar {
  opacity: 1;
}

.Toastify__progress-bar--primary {
  background: $primary;
}

.Toastify__progress-bar--secondary {
  background: $gray-800;
}

.Toastify__progress-bar--success {
  background: $success;
}

.Toastify__progress-bar--danger {
  background: $danger;
}

.Toastify__progress-bar--warning {
  background: $warning;
}

.Toastify__progress-bar--info {
  background: $info;
}

.Toastify__progress-bar--dark {
  background: $dark;
}

.Toastify__progress-bar--light {
  background: $light;
}
