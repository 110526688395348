// Button override helper
@mixin button-variant-override(
  $background: null,
  $border: null,
  $color: null,
  $hover-background: null,
  $hover-border: null,
  $hover-color: null,
  $active-background: $hover-background,
  $active-border: $hover-border,
  $active-color: $hover-color
) {
  @include set-css-var("btn-bg", $background);
  @include set-css-var("btn-border-color", $border);
  @include set-css-var("btn-color", $color);
  @include set-css-var("btn-hover-bg", $hover-background);
  @include set-css-var("btn-hover-border-color", $hover-border);
  @include set-css-var("btn-hover-color", $hover-color);
  @include set-css-var("btn-active-bg", $active-background);
  @include set-css-var("btn-active-border-color", $active-border);
  @include set-css-var("btn-active-color", $active-color);
}

// Button outline fill override helper
@mixin button-outline-fill-variant-override(
  $background: null
) {
  &.btn-outline-fill {
    @include set-css-var("btn-bg", $background);
  }
}

// Button link helper
@mixin button-link-variant-override(
  $color,
  $hover-color
) {
  @include set-css-var("btn-font-weight", $link-font-weight);
  @include set-css-var("btn-bg", transparent);
  @include set-css-var("btn-border-color", transparent);
  @include set-css-var("btn-color", $color);
  @include set-css-var("btn-hover-bg", transparent);
  @include set-css-var("btn-hover-border-color", transparent);
  @include set-css-var("btn-hover-color", $hover-color);
  @include set-css-var("btn-active-bg", transparent);
  @include set-css-var("btn-active-border-color", transparent);
  @include set-css-var("btn-active-color", $hover-color);
  @include set-css-var("btn-disabled-bg", transparent);
  @include set-css-var("btn-disabled-border-color", transparent);
  @include set-css-var("btn-disabled-color", $btn-link-disabled-color);
  @include set-css-var("btn-box-shadow", none);

  text-decoration: $link-decoration;

  &:hover,
  &:focus-visible {
    background-color: var(--#{$prefix}btn-hover-bg);
    border-color: var(--#{$prefix}btn-hover-border-color);
    color: var(--#{$prefix}btn-hover-color);
    text-decoration: $link-hover-decoration;
  }
}

// Button anchor helper
@mixin button-anchor-variant-override(
  $color: null,
  $hover-color: null
) {
  @include button-link-variant-override($color, $hover-color);

  border: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  min-width: 0;
  padding: 0;
  text-align: inherit;
  vertical-align: inherit;

  &.disabled {
    @include set-css-var("btn-disabled-bg", transparent);
  }
}

// Custom button color helper
@mixin button-custom-color-variant-override(
  $custom-color: null,
) {
  @include set-css-var("btn-bg", $custom-color);
  @include set-css-var("btn-color", $white);
  @include set-css-var("btn-border-color", $custom-color);
  @include set-css-var("btn-hover-bg", $custom-color);
  @include set-css-var("btn-hover-border-color", $custom-color);
  @include set-css-var("btn-active-bg", $custom-color);
  @include set-css-var("btn-active-border-color", $custom-color);
}

// Custom button outline color helper
@mixin button-custom-color-outline-variant-override(
  $custom-color: null,
) {
  @include set-css-var("btn-color", $custom-color);
  @include set-css-var("btn-border-color", $custom-color);
  @include set-css-var("btn-hover-bg", $custom-color);
  @include set-css-var("btn-hover-border-color", $custom-color);
  @include set-css-var("btn-active-bg", $custom-color);
  @include set-css-var("btn-active-border-color", $custom-color);
}

.btn {
  &.disabled {
    @include set-css-var("btn-disabled-bg", $btn-disabled-bg);
    @include set-css-var("btn-disabled-border-color", $btn-disabled-border-color);
    @include set-css-var("btn-disabled-color", $btn-disabled-color);
  }

  min-width: $btn-min-width;

  &.rounded-pill {
    min-width: $btn-min-width-pill;
  }
}

.btn-sm {
  line-height: $btn-line-height-sm;
  min-width: $btn-min-width-sm;

  &.rounded-pill {
    min-width: $btn-min-width-pill-sm;
  }
}

.btn-primary {
  @include button-variant-override(null, null, $white, $blue-400, $blue-400, $white);
}

.btn-outline-primary {
  @include button-variant-override(null, null, null, $blue-400, $blue-400, $white);
  @include button-outline-fill-variant-override($white);
}

.btn-secondary {
  @include button-variant-override(null, null, $white, $gray-800, $gray-800, $white);
}

.btn-outline-secondary {
  @include button-variant-override(null, null, $gray-800, $gray-800, $gray-800, $white);
  @include button-outline-fill-variant-override($white);
}

.btn-success {
  @include button-variant-override($success, $success, $white, $green-400, $green-400, $white);
}

.btn-outline-success {
  @include button-variant-override(null, null, null, $green-400, $green-400, $white);
  @include button-outline-fill-variant-override($white);
}

.btn-warning {
  @include button-variant-override(null, null, $white, $yellow-400, $yellow-400, $white);
}

.btn-outline-warning {
  @include button-variant-override(null, null, null, $yellow-400, $yellow-400, $white);
  @include button-outline-fill-variant-override($white);
}

.btn-info {
  @include button-variant-override($cyan-400, $cyan-400, $white, $cyan-500, $cyan-500, $white);
}

.btn-outline-info {
  @include button-variant-override(null, null, null, $cyan-500, $cyan-500, $white);
  @include button-outline-fill-variant-override($white);
}

.btn-danger {
  @include button-variant-override(null, null, null, $red-400, $red-400, null);
  @include button-outline-fill-variant-override($white);
}

.btn-outline-danger {
  @include button-variant-override(null, null, null, $red-400, $red-400, null);
  @include button-outline-fill-variant-override($white);
}

.btn-light {
  @include button-variant-override(null, null, null, $body-color, $body-color, $white);
}

.btn-outline-light {
  @include button-variant-override(null, null, null, $body-color, $body-color, $white);
  @include button-outline-fill-variant-override($secondary);
}

.btn-dark {
  @include button-variant-override($body-color, $body-color, null, $secondary, $secondary, $white);
}

.btn-outline-dark {
  @include button-variant-override(null, null, null, $secondary, $secondary, $white);
  @include button-outline-fill-variant-override($white);
}

.btn-stullerpay {
  @include button-variant($stullerpay-300, $stullerpay-300, $white, $stullerpay-400, $stullerpay-400, $white);
}

.btn-outline-stullerpay {
  @include button-outline-variant($stullerpay-300, $white, $stullerpay-400);
  @include button-outline-fill-variant-override($white);
}

.btn-link {
  @include button-link-variant-override($btn-link-color, $btn-link-hover-color);

  &.alert-link {
    @include set-css-var("btn-color", var(--#{$prefix}alert-link-color));
  }
}

.btn-outline-link {
  @include button-link-variant-override($btn-link-color, $btn-link-hover-color);
}

.btn-link-dark {
  @include button-link-variant-override($body-color, $gray-700);
}

.btn-outline-link-dark {
  @include button-link-variant-override($body-color, $gray-700);
}

.btn-anchor {
  @include button-anchor-variant-override($link-color, $link-hover-color);

  &.alert-link {
    @include set-css-var("btn-color", var(--#{$prefix}alert-link-color));
  }
}

.btn-outline-anchor {
  @include button-anchor-variant-override($link-color, $link-hover-color);
}

.btn-anchor-dark {
  @include button-anchor-variant-override($body-color, $gray-700);
}

.btn-outline-anchor-dark {
  @include button-anchor-variant-override($body-color, $gray-700);
}

.btn-gold {
  @include button-variant($gold-300, $gold-300, $white, $gold-400, $gold-400, $white);
}

.btn-outline-gold {
  @include button-outline-variant($gold-300, $white, $gold-400);
  @include button-outline-fill-variant-override($white);
}

.btn-custom {
  @include button-custom-color-variant-override(var(--bs-btn-bg));

  &:hover,
  &:focus-visible,
  &:active,
  &:first-child:active {
    border-color: rgb(0 0 0 / 20%);
    box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 20%);
  }
}

.btn-custom-outline {
  @include button-custom-color-outline-variant-override(var(--bs-btn-color));
  @include button-outline-fill-variant-override($white);

  &:hover,
  &:focus-visible,
  &:active,
  &:first-child:active {
    border-color: rgb(0 0 0 / 20%);
    box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 20%);
  }
}

:not(.btn-link).btn-loading {
  align-items: center;
  background-color: $btn-loading-bg;
  border-color: $btn-loading-border-color;
  box-shadow: $btn-loading-box-shadow;
  color: $btn-loading-color;
  display: inline-flex;
  justify-content: center;
  opacity: $btn-loading-opacity;
  pointer-events: none;
  text-shadow: $btn-loading-text-shadow;

  & > div:first-child {
    margin-right: 5px;
  }
}
