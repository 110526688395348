.modal-dialog {
  &:not(.modal-fullscreen, .modal-fullscreen-sm-down, .modal-fullscreen-md-down, .modal-fullscreen-lg-down, .modal-fullscreen-xl-down, .modal-fullscreen-xxl-down, .modal-fullscreen-xxxl-down) {
    @include media-breakpoint-up(lg) {
      margin: $modal-dialog-margin-y-lg-up auto;
    }
  }
}

.modal-content {
  .modal-header {
    align-items: unset;
    padding-bottom: 0;

    @include media-breakpoint-up(sm) {
      padding-left: $modal-content-padding;
    }

    .modal-title {
      padding-top: $btn-close-height;
    }
  }

  .modal-body {
    padding-bottom: 2rem;
    padding-top: 1rem;

    @include media-breakpoint-up(sm) {
      padding-left: $modal-content-padding;
      padding-right: $modal-content-padding;
    }
  }

  .modal-footer {
    padding-bottom: $modal-content-padding - 7px;
    padding-top: 0;

    @include media-breakpoint-up(sm) {
      padding-left: $modal-content-padding;
      padding-right: $modal-content-padding;
    }
  }
}
