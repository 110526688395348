.spinner-border {
  --#{$prefix}spinner-vertical-align: #{$spinner-border-vertical-align};

  animation: container-rotate var(--#{$prefix}spinner-animation-speed) linear infinite;
  font-size: $font-size-base;

  .spinner-layer {
    animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    border-color: currentcolor;

    .circle-clipper {
      .circle {
        border-color: inherit;
        border-bottom-color: transparent !important;
        border-style: solid;
        border-width: var(--#{$prefix}spinner-border-width);

        &.left {
          animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          transform: rotate(129deg);
        }

        &.right {
          animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          transform: rotate(-129deg);
        }
      }
    }

    .gap-patch {
      border-color: inherit;
      left: 45%;

      .circle {
        left: -450%;
        width: 1000%;
      }
    }
  }

  @keyframes container-rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes fill-unfill-rotate {
    12.5% {
      transform: rotate(135deg);
    }

    25% {
      transform: rotate(270deg);
    }

    37.5% {
      transform: rotate(405deg);
    }

    50% {
      transform: rotate(540deg);
    }

    62.5% {
      transform: rotate(675deg);
    }

    75% {
      transform: rotate(810deg);
    }

    87.5% {
      transform: rotate(945deg);
    }

    100% {
      transform: rotate(1080deg);
    }
  }

  @keyframes left-spin {
    0% {
      transform: rotate(130deg);
    }

    50% {
      transform: rotate(-5deg);
    }

    100% {
      transform: rotate(130deg);
    }
  }

  @keyframes right-spin {
    0% {
      transform: rotate(-130deg);
    }

    50% {
      transform: rotate(5deg);
    }

    100% {
      transform: rotate(-130deg);
    }
  }
}

.spinner-border-xs {
  @include set-css-var("spinner-border-width", $spinner-border-width-xs);
  @include set-css-var("spinner-height", $spinner-height-xs);
  @include set-css-var("spinner-width", $spinner-width-xs);
  @include set-css-var("spinner-vertical-align", $spinner-border-vertical-align-xs);
}

.spinner-border-sm {
  @include set-css-var("spinner-border-width", $spinner-border-width-sm);
  @include set-css-var("spinner-height", $spinner-height-sm);
  @include set-css-var("spinner-width", $spinner-width-sm);
  @include set-css-var("spinner-vertical-align", $spinner-border-vertical-align-sm);
}

.spinner-border-lg {
  @include set-css-var("spinner-border-width", $spinner-border-width-lg);
  @include set-css-var("spinner-height", $spinner-height-lg);
  @include set-css-var("spinner-width", $spinner-width-lg);
  @include set-css-var("spinner-vertical-align", $spinner-border-vertical-align-lg);
}

.spinner-grow-xs {
  @include set-css-var("spinner-height", $spinner-height-xs);
  @include set-css-var("spinner-width", $spinner-width-xs);
}

.spinner-grow-lg {
  @include set-css-var("spinner-height", $spinner-height-lg);
  @include set-css-var("spinner-width", $spinner-width-lg);
}
