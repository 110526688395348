.loading {
  .loading-xs {
    min-height: $spinner-height-xs;
    min-width: $spinner-height-xs;
  }

  .loading-sm {
    min-height: $spinner-height-sm;
    min-width: $spinner-height-sm;
  }

  .loading-md {
    min-height: $spinner-height;
    min-width: $spinner-height;
  }

  .loading-lg {
    min-height: $spinner-height-lg;
    min-width: $spinner-height-lg;
  }
}
