.svg-stone-icon {
  --stone-icon-background: var(--svg-stone-icon-background, transparent);
  --stone-icon-shadow: var(--svg-stone-icon-shadow, transparent);
  --stone-icon-outline: var(--svg-stone-icon-outline, #{$body-color});

  fill: none;

  &:hover {
    --stone-icon-background: var(--svg-stone-icon-background-hover, var(--svg-stone-icon-background, transparent));
    --stone-icon-shadow: var(--svg-stone-icon-shadow-hover, var(--svg-stone-icon-shadow, transparent));
    --stone-icon-outline: var(--svg-stone-icon-outline-hover, var(--svg-stone-icon-outline, #{$body-color}));
  }
}
