.animation-shine {
  overflow: hidden;
  position: relative;

  @keyframes slide {
    0%,
    10%,
    90%,
    100% {
      left: -110%;
    }

    50% {
      left: 110%;
    }
  }

  &::before {
    animation-direction: alternate;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-name: slide;
    animation-timing-function: ease-in-out;
    background: linear-gradient(to right, transparent, var(--bs-animation-shine-color, rgb(255 255 255 / 50%)), transparent);
    content: "";
    height: 100%;
    left: -100px;
    position: absolute;
    top: 0;
    transform: skewX(-30deg);
    width: 40px;
  }
}

.animation-fade-in-up {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fade-in-up;

  &.delay-1s {
    animation-delay: 1s;
  }
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
