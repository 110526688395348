.btn-group {
  min-width: $btn-min-width;

  .dropdown-toggle-split {
    min-width: auto;
  }
}

.dropdown,
.dropup,
.dropend {
  .dropdown-toggle {
    &:not(.dropdown-toggle-split) {
      .dropdown-toggle-icon {
        padding-left: $dropdown-toggle-icon-spacing;
      }
    }
  }
}

.dropstart {
  .dropdown-toggle {
    &:not(.dropdown-toggle-split) {
      direction: rtl;

      .dropdown-toggle-icon {
        padding-right: $dropdown-toggle-icon-spacing;
      }
    }
  }
}
