// links
a {
  font-weight: $link-font-weight;
}

// lists
li {
  padding-bottom: 0.5rem;

  ol,
  ul {
    padding-top: 0.5rem;
  }
}

// focus-visible
:focus-visible {
  outline-color: $secondary;
}

b,
strong {
  font-weight: $font-weight-bold;
}
