// Custom bootstrap
// Documentation: https://getbootstrap.com/docs/5.1/customize/sass

// Functions
@import "./functions";
@import "bootstrap/scss/functions";

// Variables
@import "./variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// Maps
@import "./maps";
@import "bootstrap/scss/maps";

// Mixins
@import "./mixins";
@import "bootstrap/scss/mixins";

// Root (CSS vars)
@import "./root";
@import "bootstrap/scss/root";

// Utilities
@import "bootstrap/scss/utilities";
@import "./utilities";

// Layout & components
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/card";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/close";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/images";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/type";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

// Custom styles
@import "./reboot";
@import "./helpers/colored-links";
@import "./helpers/line-clamp";
@import "./accordion";
@import "./alert";
@import "./animations";
@import "./background";
@import "./breadcrumb";
@import "./button-group";
@import "./buttons";
@import "./carousel";
@import "./display";
@import "./dropdown";
@import "./forms/form-check";
@import "./forms/form-control";
@import "./forms/form-credit-card-number-input";
@import "./forms/form-date-picker";
@import "./forms/form-option-card";
@import "./forms/form-phone-number-input";
@import "./forms/form-recaptcha";
@import "./forms/form-select-dropdown";
@import "./forms/form-select";
@import "./forms/form-number";
@import "./forms/input-group";
@import "./forms/labels";
@import "./forms/validation";
@import "./loading";
@import "./modal";
@import "./nav";
@import "./offcanvas";
@import "./scroll";
@import "./pagination";
@import "./popover";
@import "./progress";
@import "./shadow-container";
@import "./showcase";
@import "./spinners";
@import "./stone-shape-card";
@import "./tables";
@import "./svg";
@import "./toasts";
@import "./type";
@import "./zone";

// Font awesome
@import "@fortawesome/fontawesome-svg-core/styles.css";

// react-international-phone
@import "react-international-phone/style.css";
