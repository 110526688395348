.carousel-control-prev,
.carousel-control-next {
  &:hover,
  &:focus {
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: $carousel-control-icon-hover-bg-color;
    }
  }
}

.carousel-control-prev {
  justify-content: start;
  padding-left: $carousel-control-spacer;
  padding-right: $carousel-control-spacer;
}

.carousel-control-next {
  justify-content: end;
  padding-left: $carousel-control-spacer;
  padding-right: $carousel-control-spacer;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: $carousel-control-icon-bg-color;
  background-size: $carousel-control-icon-width;
  border: 1px solid;
  border-radius: 50%;
  height: $carousel-control-icon-bg-width;
  padding: 10px;
  transition: $carousel-control-transition;
  width: $carousel-control-icon-bg-width;
}

.carousel-control-prev-icon {
  background-position: $carousel-control-prev-icon-bg-position;
}

.carousel-control-next-icon {
  background-position: $carousel-control-next-icon-bg-position;
}

.carousel-indicators {
  background-color: $carousel-indicators-bg-color;
  border-radius: $border-radius-pill;
  box-shadow: $box-shadow-sm;
  left: 50%;
  margin-bottom: $carousel-indicators-margin-bottom;
  margin-left: auto;
  margin-right: auto;
  padding: $carousel-indicators-padding-y $carousel-indicators-padding-x;
  right: unset;
  transform: translate(-50%);

  [data-bs-target] {
    background-clip: unset;
    background-color: $carousel-indicator-bg;
    border-color: $carousel-indicator-border-color;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;

    &.active {
      background-color: $carousel-indicator-active-bg;
      border-color: $carousel-indicator-active-bg;
    }
  }
}

// Alert override helper
@mixin carousel-dark-components() {
  .carousel-caption,
  &.carousel-caption {
    color: $carousel-dark-caption-color;
  }

  .carousel-control-prev,
  &.carousel-control-prev,
  .carousel-control-next,
  &.carousel-control-next {
    &:hover,
    &:focus {
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        background-color: $carousel-dark-control-icon-hover-bg-color;
      }
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: $carousel-dark-control-icon-bg-color;
      filter: $carousel-dark-control-icon-filter;
    }
  }

  .carousel-indicators,
  &.carousel-indicators {
    background-color: $carousel-dark-indicators-bg-color;
    box-shadow: none;

    [data-bs-target] {
      background-color: $carousel-dark-indicator-bg;
      border-color: $carousel-dark-indicator-border-color;

      &.active {
        background-color: $carousel-dark-indicator-active-bg;
        border-color: $carousel-dark-indicator-active-bg;
      }
    }
  }
}

@include color-mode(dark) {
  @include carousel-dark-components;

  .carousel,
  &.carousel {
    @include carousel-dark-components;
  }
}

.carousel-popout-indicators {
  .carousel-control-prev,
  .carousel-control-next {
    bottom: $carousel-indicators-margin-bottom + $carousel-indicator-height + (2 * $carousel-indicators-padding-y);
  }

  .carousel-indicators {
    border: 1px solid $gray-600;
    box-shadow: none;
    left: unset;
    margin: $carousel-control-spacer auto 0 auto;
    position: static;
    right: unset;
    transform: none;
    width: fit-content;
  }
}

.carousel-popout-controls {
  &.carousel {
    display: flex;
    flex-wrap: wrap;
  }

  .carousel-inner {
    flex: 1 1 1%;
    width: auto;
  }

  .carousel-control-prev,
  .carousel-control-next {
    flex: 0 0 auto;
    position: static;
    width: auto;
  }

  .carousel-control-prev {
    order: -1;
    padding-left: 0;
  }

  .carousel-control-next {
    padding-right: 0;
  }
}
