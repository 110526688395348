/* stylelint-disable selector-class-pattern */

.form-select-dropdown {
  background-color: $form-select-bg;
  display: block;
  width: 100%;

  &.rounded-pill {
    .form-select-dropdown__control {
      padding-left: $input-padding-pill-x;

      &:not(.form-select-dropdown__control--menu-is-open) {
        @include border-radius(var(--bs-border-radius-pill));
      }

      &.form-select-dropdown__control--menu-is-open {
        @include border-top-radius($form-select-pill-open-border-radius);
      }
    }

    &.form-select-dropdown-sm .form-select-dropdown__control {
      padding-left: $input-padding-pill-x-sm;
    }

    &.form-select-dropdown-lg .form-select-dropdown__control {
      padding-left: $input-padding-pill-x-lg;
    }
  }

  &.disabled {
    background-color: $form-select-disabled-bg !important;
    border-color: $form-select-disabled-border-color;
    color: $form-select-disabled-color;
  }

  &.has-more-options .form-select-dropdown__menu .form-select-dropdown__option:last-child {
    background: radial-gradient(farthest-side at 50% 100%, rgb(0 0 0 / 20%), rgb(0 0 0 / 0%)) center bottom;
  }
}

.form-select-dropdown__control {
  appearance: none;
  background-color: $form-select-bg;
  border: $form-select-border-width solid $form-select-border-color;
  box-shadow: $form-select-box-shadow !important;
  color: $form-select-color;
  font-family: $form-select-font-family;
  font-weight: $form-select-font-weight;
  line-height: $form-select-line-height;
  min-height: auto !important;
  padding: $form-select-padding-y 0 $form-select-padding-y $form-select-padding-x;
  -moz-padding-start: subtract($form-select-padding-x, 3px);
  transition: $form-select-transition !important;

  @include font-size($form-select-font-size);
  @include border-radius($form-select-border-radius, 0);

  .form-select-dropdown__input-container {
    cursor: text;
  }

  .form-select-dropdown__value-container--is-multi {
    cursor: pointer;
  }
}

.form-select-dropdown__control--is-disabled {
  background-color: $form-select-disabled-bg;
  border-color: $form-select-disabled-border-color;
  color: $form-select-disabled-color;

  .form-select-dropdown__value-container {
    color: $form-select-disabled-color;
  }

  .form-select-dropdown__indicators {
    opacity: 0.5;
  }
}

.form-select-dropdown__control--is-focused,
.form-select-dropdown__control--menu-is-open {
  border-color: $form-select-focus-border-color;
}

.form-select-dropdown__control--menu-is-open {
  @include border-bottom-radius(0);
}

.form-select-dropdown__placeholder {
  color: $input-placeholder-color;
}

.form-select-dropdown__indicator-separator {
  display: none !important;
}

.form-select-dropdown__dropdown-indicator {
  background-color: transparent;
  background-image: escape-svg($form-select-indicator);
  background-position: $form-select-bg-position;
  background-repeat: no-repeat;
  background-size: $form-select-bg-size;
  height: 100%;
  width: $form-select-indicator-padding;

  svg {
    display: none;
  }
}

.form-select-dropdown__clear-indicator {
  background-color: transparent;
  background-image: escape-svg($form-select-clear-indicator);
  background-position: $form-select-clear-indicator-bg-position;
  background-repeat: no-repeat;
  background-size: $form-select-clear-indicator-bg-size;
  height: 100%;
  width: $form-select-clear-indicator-width;

  &:hover {
    cursor: pointer;
  }

  svg {
    display: none;
  }
}

.form-select-dropdown__indicators {
  .form-select-dropdown__clear-indicator:only-child {
    margin-right: $form-select-padding-x;
  }
}

.form-select-dropdown__menu {
  appearance: none;
  background-color: $form-select-bg;
  border: $form-select-border-width solid $form-select-focus-border-color;
  box-shadow: $form-select-box-shadow !important;
  color: $form-select-color;
  font-family: $form-select-font-family;
  font-weight: $form-select-font-weight;
  line-height: $form-select-line-height;
  margin-top: -1px;
  transition: $form-select-transition !important;
  z-index: $zindex-dropdown !important;

  @include font-size($form-select-font-size);
  @include border-bottom-radius($form-select-border-radius);
}

.form-select-dropdown__menu-list {
  @include scrollbar;

  > :last-child.form-select-dropdown__option {
    @include border-bottom-radius($form-select-border-radius);
  }
}

.form-select-dropdown__group {
  .form-select-dropdown__option {
    padding: $form-select-padding-y ($form-select-padding-x * 1.5);
  }
}

.form-select-dropdown__group-heading {
  font-weight: $form-select-option-group-font-weight;
  padding: $form-select-padding-y $form-select-padding-x;
}

.form-select-dropdown__option {
  padding: $form-select-padding-y $form-select-padding-x;

  &:hover {
    cursor: pointer;
  }
}

.form-select-dropdown__option--is-focused {
  background-color: $form-select-option-focused-bg;
  font-weight: $form-select-option-focused-font-weight;
}

.form-select-dropdown__option--is-selected {
  background-color: $form-select-option-selected-bg;
  color: $form-select-option-selected-font-color;
  font-weight: $form-select-option-selected-font-weight;
}

.form-select-dropdown__menu-notice {
  font-weight: $font-weight-bold;
  padding: $form-select-padding-y $form-select-padding-x;
}

.form-select-dropdown-sm {
  &.rounded-pill {
    .form-select-dropdown__control {
      &.form-select-dropdown__control--menu-is-open {
        @include border-top-radius($form-select-pill-open-border-radius-sm);
      }
    }
  }

  .form-select-dropdown__control {
    padding: $form-select-padding-y-sm 0 $form-select-padding-y-sm $form-select-padding-x-sm;

    @include font-size($input-font-size-sm);
    @include border-radius($input-border-radius-sm);

    &.form-select-dropdown__control--menu-is-open {
      @include border-bottom-radius(0);
    }
  }

  .form-select-dropdown__dropdown-indicator {
    background-size: $form-select-bg-size-sm;
    width: $form-select-indicator-padding-sm;
  }

  .form-select-dropdown__clear-indicator {
    background-size: $form-select-bg-size-sm;
    width: $form-select-clear-indicator-width-sm;
  }

  .form-select-dropdown__menu {
    @include font-size($input-font-size-sm);
  }
}

.form-select-dropdown-lg {
  &.rounded-pill {
    .form-select-dropdown__control {
      &.form-select-dropdown__control--menu-is-open {
        @include border-top-radius($form-select-pill-open-border-radius-lg);
      }
    }
  }

  .form-select-dropdown__control {
    padding: $form-select-padding-y-lg 0 $form-select-padding-y-lg $form-select-padding-x-lg;

    @include font-size($input-font-size-lg);
    @include border-radius($input-border-radius-lg);

    &.form-select-dropdown__control--menu-is-open {
      @include border-bottom-radius(0);
    }
  }

  .form-select-dropdown__dropdown-indicator {
    background-size: $form-select-bg-size-lg;
    width: $form-select-indicator-padding-lg;
  }

  .form-select-dropdown__clear-indicator {
    background-size: $form-select-bg-size-lg;
    width: $form-select-clear-indicator-width-lg;
  }

  .form-select-dropdown__menu {
    @include font-size($input-font-size-lg);
  }
}
