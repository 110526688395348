:root,
[data-bs-theme="light"] {
  @each $color, $value in $all-colors {
    --#{$prefix}#{$color}: #{$value};
  }

  @each $color, $value in $all-colors-rgb {
    --#{$prefix}#{$color}-rgb: #{$value};
  }

  // Outline
  --#{$prefix}outline-width: #{$outline-width};
  --#{$prefix}outline-style: #{$outline-style};
  --#{$prefix}outline-color: #{$outline-color};
  --#{$prefix}outline-color-translucent: #{$outline-color-translucent};

  // Border radius
  --#{$prefix}border-radius-xxxl: #{$border-radius-xxxl};
}
